.payment-container {
  padding: 20px 30px;
  width: 100%;
  border: 1px solid var(--border);
  border-radius: 5px;
}
.payment-container.show {
  border: 1px solid var(--secondary);
}
.left-tread-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.payment-method {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment-method .radio {
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  label {
    cursor: pointer;
  }
  input {
    width: 25px;
    height: 25px;
  }
}

.payment-body {
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-height: 0; /* Initial state: hidden */
  overflow: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.payment-body p {
  font-size: 12px;
  font-weight: 300;
  padding: 10px 0px;
}
.payment-body.show {
  max-height: 500px; /* Adjust according to the content height */
  opacity: 1;
}

.payment-body.show .paypal-pay {
  padding-top: 55px;
}
.payment-body.show .wire-transfer {
  padding-top: 55px;
}
.payment-body.hide {
  max-height: 0;
  opacity: 0;
}
.wire-transfer-body {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;

  @media (max-width: 520px) {
    flex-direction: column;
  }
}
.wire-transfer-body-right {
  width: 280px;
}
.wire-transfer-body-right span {
  font-weight: 500;
  font-size: 14px;
}

/* right side bar */
.product-img-title {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  padding-bottom: 20px;
}
.product-img-title img {
  width: 35px;
  height: 35px;
  border-radius: 8px;
  background-color: #e8efee;
  object-fit: contain;
}
.product-img-title .product-description {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 12px;
}
.product-description p:first-child {
  text-decoration: underline;
  color: var(--secondary);
  cursor: pointer;
}
.product-description p {
  font-weight: 500;
}
.paypal_btn {
  width: 100%;
  margin-top: 16px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 208, 0);
  &:hover {
    background-color: rgb(221, 181, 3) !important;
  }
}
.frostflow-doc {
  padding-top: 20px;
  font-size: 10px;
  font-weight: 300;
  color: #757575;
}
