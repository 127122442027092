
/* Main container */
.reviews-section {
    padding-top: 30px;
  }
  
  /* Individual Review */
  .review-item {
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
  
  /* User Info */
  .review-user-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .review-avatar {
    background-color: var(--constantPrimary);
    margin-right: 10px;
  }
  
  .review-user-name {
    margin: 0;
  }
  
  .review-date {
    margin: 0;
    font-size: 12px;
    color: #888;
  }
  
  /* Review Content */
  .review-content {
    border-left: 1px solid #ddd;
    padding-left: 10px;
  }
  
  .review-rate {
    color: #ff8c00;
    margin-bottom: 10px;
  }
  
  .review-text {
    margin: 10px 0;
    width: 50%;
  
    @media (max-width:768px){
      width: 100%;
    }
  }
  
  /* Photos */
  .review-photos {
    display: flex;
    gap: 10px;
  }
  
  .review-photo {
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: 5px;
  }