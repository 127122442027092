.shopping-cart-container {
  padding: 120px 90px;

  h2 {
    font-size: 29px;
    font-weight: 700;
    line-height: 34.8px;
  }

  .no-addtocart {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    h1 {
      font-family: "Inter";
      font-size: 30px;
      font-weight: 700;
      line-height: 45px;
      letter-spacing: -0.02em;
    }

    p {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 20.22px;
      text-align: center;
      color: var(--textGray70);
    }
  }

  .shopping-cart-items {
    padding-top: 20px;
    display: flex;
    gap: 30px;

    .left-cart-item {
      flex: 3;

      .select-all {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        .select-all-checkbox {
          width: 300px;
        }

        .line {
          min-width: 100px !important;

          @media (max-width: 443px) {
            display: none !important;
          }
        }
      }

      .card-product-detail {
        margin-top: 20px;
      }

      .card-products {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .product-image {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding-right: 14px;

          .product-image-container {
            padding-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;

            .product-image-detail {
              display: flex;
              gap: 20px;

              img {
                width: 120px;
                height: 120px;
                object-fit: cover;
                border-radius: 5px;
                background-color: var(--bgPrimary);
                border: 1px solid var(--border);

                @media (max-width: 438px) {
                  width: 90px;
                  height: 90px;
                }
              }

              .product-detail {
                display: flex;
                flex-direction: column;
                gap: 5px;

                @media (max-width: 438px) {
                  p {
                    font-size: 9px;
                  }
                }
                .product-title {
                  font-family: "Inter";
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 22px;
                }
                .product-stock {
                  font-family: "Inter";
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 18px;
                  color: rgba(208, 74, 10, 1);
                  .fire {
                    color: rgba(208, 74, 10, 1);
                  }
                }
                .product-weight {
                  font-family: "Inter";
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 18px;
                  color: var(--textGray70);
                }
              }
            }
          }
        }
      }
    }

    .right-cart-item {
      flex: 1;
      box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 20px;
      height: fit-content;
      margin-top: 20px;

      .right-cart-container .total-pay {
        margin: 10px 0px;
      }
    }

    @media (max-width: 986px) {
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    padding: 120px 30px;
  }
}

.alert-product-stock {
  padding: 10px 0px;
  font-size: 14px;
  color: #b3261e;
  display: flex;
  font-family: "Inter";
  font-weight: 400;
  line-height: 18px;

  .alert-icon {
    color: #b3261e;
    margin-top: 1px;
  }
}

.product-you-may-like {
  padding-top: 20px;

  h2 {
    padding: 10px 0px;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #191a1a !important;
  border-color: transparent;
}

.ant-checkbox + span {
  padding-inline-start: 8px;
  padding-inline-end: 8px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  @media (max-width: 665px) {
    font-size: 14px;
  }
  @media (max-width: 438px) {
    font-size: 12px;
  }
}
