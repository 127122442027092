.wire-transfer-main{
    padding: 20px 100px;
@media (max-width:786px) {
    padding: 20px 20px;
    
}
}
.wire-transfer-container{
    border: 11px solid  var(--bgSecondary);
    margin: 30px 20px;
    padding: 40px 150px;
    display: flex;
    flex-direction: column;


    .order-num{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .order_num{
            font-size: 14px;
        }
        .order_num > span{
         color: var(--primary);
        }
    }
    h3{
        padding: 40px 0px;
        font-size: 24px;
    }

    .amount-summary{
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 20px;

        p{
            font-size: 14px;
        }

        .payment >span{
            color: #ff5e00;
            font-weight: 400;
            padding-left: 5px;
        }
        .process > span, .time >span{
            font-weight: 500;
            padding-left: 5px;
        }
        .btn-grounp{
            display: flex;
            gap: 10px;

            .btn{
                background-color: transparent;
                color: #ff5e00;
                border: 1px solid #ff5e00;
                border-radius: 20px;
                padding: 10px 30px;
            }
        }
    }

    .alert{
        padding: 20px 0px;
    }
    .payment-table table {
        width: 100%;
        border-collapse: collapse;
        font-family: Arial, sans-serif;
      }
      .bg-gray{
        background-color: var(--bgSecondary);
      }
      
      .payment-table th {
        text-align: left;
        padding: 10px;
        width: 30%;
        font-weight: 300;
        border: 1px solid #ddd;
      }
      
      .payment-table td {
        padding: 10px;
        border: 1px solid #ddd;
        font-weight: 300;
      }
      .payment-table td .bold{
        font-weight: 600;
        padding-left: 5px;
      }
      .payment-table a {
        color: #007bff;
        text-decoration: none;
      }
      
      .payment-table a:hover {
        text-decoration: underline;
      }
      
      .payment-table img {
        vertical-align: middle;
      }
      
      .payment-table .remark {
        color: #ff5e00;
        font-weight: bold;
      }


    .notice{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--bgSecondary);
        gap: 20px;
        margin-top: 20px;
        padding: 20px;
        margin-bottom: 20px;


        .pera{
            font-size: 14px;
            font-weight: 300;
            line-height: 1.8;
        }
    }


      @media (max-width:924px) {
        padding: 20px 40px;
        
      }
      
}

.footer-breadcrum{
    display: flex;
    gap: 40px;


    p{
        color: var(--primary);
    }
    .f-b-title{
      color: var(--primary);
    }
}