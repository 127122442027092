.profileManagLayout {
  --height: calc(100dvh - 100px);
  --widthSider: 265px;

  width: 100%;
  height: 100dvh;

  .profileManagBody {
    width: 100%;
    height: var(--height);

    display: flex;

    .profileManagSider {
      width: var(--widthSider);
      height: var(--height);
      border-right: 1px solid var(--border);

      .header {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        padding-inline: 30px;

        h5 {
          color: var(--textGray100);
          font-family: Inter;
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
          letter-spacing: -0.02em;
        }
      }

      .listBox {
        width: 100%;
        display: flex;
        flex-direction: column;

        .list {
          width: 100%;
          height: 40px;
          padding-inline: 30px;
          display: flex;
          align-items: center;
          gap: 15px;
          cursor: pointer;

          p {
            color: rgba(153, 160, 175, 1);
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }

          svg {
            color: rgba(153, 160, 175, 1);
          }

          &:hover {
            transition: all 0.3s ease;
            background-color: var(--primaryBlue90);
            p {
              color: #fff;
            }
            svg {
              color: #fff;
            }
          }
        }

        .active {
          background-color: var(--primaryBlue90);
          p {
            color: #fff;
          }
          svg {
            color: #fff;
          }
        }
      }
    }

    .profileManagContent {
      width: calc(100% - var(--widthSider));
      height: var(--height);
      overflow-y: auto;
    }
  }
}
