.review-modal-parent{
  .ant-modal-content{
    padding: 16px 24px 24px 24px !important;
    .ant-modal-body{
      margin-top: 0px !important;
    }
  }
}

.review-modal {
  .title {
    font-size: 22px;
    color: var(--primaryText);
  }
  .content-parent {
    margin-top: 15px;
    border-top: 1px solid var(--border);
    display: flex;
    flex-direction: column;
    .field-label-parent {
      margin: 20px 0px 10px 0px;
      display: flex;
      flex-direction: column;
      gap: 13px;
      label {
        font-size: 17px;
        font-weight: 400;
        color: var(--primaryText);
      }
      .rating-select {
        height: 50px;
        .ant-select-selector {
          border-radius: 25px;
        }
      }

      .upload-box {
        width: 100%;
        height: 206px;
        border-radius: 12px;
        .ant-upload {
          background-color: transparent;
          .ant-upload-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 16.8px;
            letter-spacing: 0%;
            color: var(--textGraySand);
            span {
              color: var(--primary);
            }
          }
        }
      }

      .files-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
        .file-item {
          display: flex;
          padding: 20px 15px;
          align-items: center;
          gap: 14px;
          border: 2px solid var(--border);
          border-radius: 14px;
          width: 100%;
          .icon-parent {
            height: 40px;
            width: 40px;
            overflow: hidden;
            display: flex;
            align-items: center;
            border-radius: 5px;
            justify-content: center;
            background-color: var(--primaryLightBlue5);
            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
            .video-icon{
              font-size: 27px;
              color: var(--primary);
            }
          }
          p {
            flex: 1;
          }
          .deleteIcon {
            font-size: 22px;
            cursor: pointer;
          }
        }
      }

      .feedback-textarea {
        &::placeholder {
          color: var(--textGray70);
          font-size: 16px;
        }
      }
    }
    .save-btn {
      background-color: var(--primary);
      border-radius: 30px;
      padding: 15px;
      width: 220px;
      font-weight: 300;
      font-size: 18px;
      color: var(--bgWhite);
      margin: 20px auto 0px auto;
    }
  }
}

.reviewModal-select-item {
  display: flex;
  align-items: center;
  svg {
    color: #e57722;
  }
  p {
    margin-left: 10px;
    color: var(--textGray70);
    font-size: 16px;
    font-weight: 400;
  }
}

.review-published-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  text-align: center;
  h5 {
    font-size: 25px;
    font-weight: 700;
    color: var(--primaryText);
  }
  p:nth-of-type(1) {
    color: var(--textGray70);
    font-size: 19px;
  }
  p:nth-of-type(2) {
    color: var(--placeholder);
    font-size: 18px;
  }
  .btn {
    background-color: var(--primary);
    border-radius: 30px;
    padding: 15px;
    width: 220px;
    font-weight: 300;
    font-size: 18px;
    color: var(--bgWhite);
  }
}
