.signinWithBox {
  width: 100%;
  border-radius: 30px;
  border: 1px solid var(--borderBlack);
  cursor: pointer;
  padding: 8px 15px 8px 25px;

  &:hover {
    border: 1px solid var(--constantPrimary);
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.28px;
    color: var(--textGray100);
  }

  div {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #ededed;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
