.profileContainer {
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .profileBody {
    width: min(100%, 760px);
    border: 1px solid rgba(228, 231, 233, 1);
    border-radius: 4px;
    margin: 86px 100px 0px 100px;

    .profileHeader {
      padding: 16px 30px;
      border-bottom: 1px solid rgba(228, 231, 233, 1);
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        color: var(--textGray100);
        font-size: 16px;
        font-weight: 700;
        line-height: 19.2px;
      }

      svg {
        cursor: pointer;
      }
    }

    .viewBox {
      padding: 25px 50px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .viewHeader {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;

        .profileImage {
          width: 75px;
          height: 75px;
          border-radius: 50%;
          background-color: var(--border);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }

        .viewHeaderRight {
          .nameText {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            color: var(--textGray100);
          }
          .addressText {
            font-size: 18px;
            font-weight: 400;
            line-height: 21.6px;
            color: rgba(95, 108, 114, 1);
          }
        }
      }

      .ViewProfileDetail {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .row {
          p {
            color: var(--textGray100);
            font-size: 16px;
            font-weight: 400;
            line-height: 19.2px;
          }
          span {
            font-size: 16px;
            font-weight: 400;
            line-height: 19.2px;
            color: var(--textGray95);

            margin-left: 5px;
          }
        }
      }

      .footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          border: 1px solid var(--primaryBlue90);
          color: var(--primaryBlue90);
          padding-inline: 25px;
        }
      }
    }

    .editBox {
      padding: 30px;

      .uploadBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        .wrapper {
          height: 150px !important;
          width: 150px !important;
          border-radius: 50%;

          label {
            display: flex;
            background-color: #fafafa;
            cursor: pointer;
            width: 100%;
            height: 100%;
            border-radius: 50%;

            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            transition: all 0.3s ease;

            &:hover {
              span {
                opacity: 1;
              }
            }

            span {
              display: block;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              opacity: 0;

              transition: opacity 0.3s ease;

              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 15px;

              background-color: rgba(0, 0, 0, 0.5);

              p {
                font-size: 13px;
                color: #fff;
              }
              svg {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .shippingAddressBody {
    width: min(100%, 760px);
    border: 1px solid rgba(228, 231, 233, 1);
    border-radius: 4px;
    margin: 0px 100px 0px 100px;

    .shippingAddressHeader {
      padding: 16px 30px;
      border-bottom: 1px solid rgba(228, 231, 233, 1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      p {
        color: var(--textGray100);
        font-size: 16px;
        font-weight: 700;
        line-height: 19.2px;
      }

      svg {
        cursor: pointer;
        font-size: 20px;
      }
    }

    .shippingAddressContent {
      padding: 24px 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .shippingAddressCard {
        padding: 16px;
        border: 1px solid rgba(228, 231, 233, 1);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        .dflex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          h6 {
            color: var(--textGray100);
            font-family: "Inter";
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
          svg {
            font-size: 25px;
            cursor: pointer;
            color: var(--textGray100);
          }
        }
        .addressParent {
          p {
            font-family: "Inter";
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: var(--textGray95);
          }
        }

        span {
          display: flex;
          align-items: center;
          label {
            font-family: "Inter";
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
          p {
            font-family: "Inter";
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: var(--textGray95);
          }
        }
        .actionsParent {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;
          .ant-checkbox + span {
            font-size: 16px !important;
            font-weight: 400 !important;
            line-height: 20px !important;
            font-family: "Cabinet Grotesk" !important;
          }
          button {
            border: 1px solid var(--primaryBlue90);
            color: var(--primaryBlue90);
            padding-inline: 25px;
          }
        }
      }
    }
  }

  .shippingAddressContainer {
    .breadcrumbBox {
      margin: 13px 0px 0px 75px;
      .breadCrumbItem {
        cursor: pointer;
      }
    }
    .shippingAddressEditBody {
      width: min(100%, 908px);
      border: 1px solid rgba(228, 231, 233, 1);
      padding: 31px 32px 56px 32px;
      margin: 74px 100px 0px 100px;
      span {
        display: flex;
        align-items: center;
        gap: 12px;
        h5 {
          font-family: "Inter";
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
        }
        svg {
          font-size: 23px;
        }
      }
      .noteParent {
        display: flex;
        align-items: center;
        gap: 8px;
        p {
          font-family: "Inter";
          font-size: 14px;
          font-weight: 400;
          line-height: 16.8px;
          margin: 23px 0px 20px 0px;
        }
        svg {
          font-size: 23px;
        }
      }
    }
  }

  .changePasswordBody {
    width: min(100%, 760px);
    border: 1px solid rgba(228, 231, 233, 1);
    border-radius: 4px;
    margin: 0px 100px 0px 100px;

    .changePasswordHeader {
      padding: 16px 30px;
      border-bottom: 1px solid rgba(228, 231, 233, 1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      p {
        color: var(--textGray100);
        font-size: 16px;
        font-weight: 700;
        line-height: 19.2px;
      }
    }

    .changePasswordContent {
      padding: 24px 36px;
      .changePasswordForm {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .ant-form-item {
          margin-bottom: 0px;
        }
        .save-btn-form-item {
          display: flex;
          justify-content: center;
          margin-top: 10px;
        }
      }
    }
  }

  .notificationSettingBody {
    width: min(100%, 760px);
    border: 1px solid rgba(228, 231, 233, 1);
    border-radius: 4px;
    margin: 0px 100px 0px 100px;

    .notificationSettingHeader {
      padding: 16px 30px;
      border-bottom: 1px solid rgba(228, 231, 233, 1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      p {
        color: var(--textGray100);
        font-size: 16px;
        font-weight: 700;
        line-height: 19.2px;
      }
    }
    .notificationsList {
      list-style: none;
      padding: 28px 38px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      li {
        display: flex;
        align-items: center;
        gap: 20px;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: var(--textGray95);
      }
    }
  }

  .closeAccountBody {
    width: min(100%, 760px);
    border: 1px solid rgba(228, 231, 233, 1);
    border-radius: 4px;
    margin: 0px 100px 0px 100px;

    .closeAccountHeader {
      padding: 16px 30px;
      border-bottom: 1px solid rgba(228, 231, 233, 1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      p {
        color: var(--textGray100);
        font-size: 16px;
        font-weight: 700;
        line-height: 19.2px;
      }
    }

    .closeAccountContent {
      display: flex;
      flex-direction: column;
      padding: 30px 0px 36px 28px;
      gap: 20px;
      p {
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        width: 80%;
        color: var(--textGray95);
        margin-bottom: 7px;
      }
      .reasonParent {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .reasonLabel {
          font-family: "Inter";
          font-size: 12px;
          font-weight: 700;
          line-height: 18px;
          color: #061a48;
        }
        .reasonSelect {
          width: 346px;
        }
      }
      .ant-checkbox-wrapper {
        width: 346px !important;
      }
      .ant-checkbox + span {
        font-family: "Inter" !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 18px !important;
        color: var(--textGray50) !important;
      }
      .btn {
        padding: 12px 24px;
        width: fit-content;
      }
    }
  }
}

.switch-style.ant-switch {
  width: 32px;
  height: 8px;
  background-color: #ecedf0; /* Light blue background */
}

.switch-style .ant-switch-handle {
  inset-inline-start: 0px;
  top: -8px;
}

.switch-style .ant-switch-handle::before {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(174, 179, 191, 1);
}

.switch-style.ant-switch-checked {
  background-color: rgba(229, 244, 255, 1);
}

.switch-style.ant-switch:hover {
  background-color: #ecedf0;
}

.switch-style.ant-switch-checked .ant-switch-handle::before {
  background-color: var(--primary);
}

.switch-style.ant-switch-checked:hover {
  background-color: rgba(229, 244, 255, 1) !important;
}
