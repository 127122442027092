/* **********************  Categories section style ********************** */
.categories-section-container {
  height: auto;
  padding: 7rem 0px;
  width: 100%;
  background-color: var(--bgSecondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.categories-section-container .hdng {
  font-size: 45px;
  line-height: 52px;
  font-weight: 500;
  text-align: center;
}

.categories-section-container .sub-hdng {
  font-size: 23px;
  line-height: 30px;
  font-weight: 300;
  text-align: center;
}

.categories-section-container .product-cards-parent {
  margin-top: 50px;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

/* ********************** Why FrostFlow section style ********************** */

.whyFrostFlow-sec-main {
  height: auto;
  width: 100%;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 7rem 0px;
}

.whyFrostFlow-sec-main .hdng {
  font-size: 45px;
  line-height: 52px;
  font-weight: 500;
  text-align: center;
  color: var(--bgWhite);
}

.whyFrostFlow-sec-main .text {
  font-size: 17px;
  line-height: 23px;
  width: 60%;
  font-weight: 300;
  text-align: center;
  color: var(--bgWhite);
}

.whyFrostFlow-sec-main .services-cards-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 35px;
  margin-top: 70px;
}

.service-card {
  min-height: 262px;
  max-height: fit-content;
  width: 313px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 9px;
  padding: 40px 20px;
  text-align: center;
}

.service-card .icon {
  font-size: 50px;
  color: var(--bgWhite);
}

.service-card .img {
  width: 43.67px;
}

.service-card .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  color: var(--bgWhite);
}

.service-card .discription {
  color: var(--bgWhite);
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
}

/* **********************  Testimonial section style ********************** */

.testimonials-main-container {
  height: auto;
  width: 100%;
  background-color: var(--shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 7rem 0px;
}

.testimonials-main-container .hdng {
  font-size: 45px;
  line-height: 52px;
  font-weight: 700;
  text-align: center;
  color: #061A48;
}

.testimonials-main-container .testimonials-carousal-main {
  width: 100%;
  margin-top: 30px;
}

.testimonials-main-container .ant-carousel {
  width: 90%;
}

.testimonials-main-container .testimonials-carousal-main .testimonial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  /* margin-bottom: 5rem; */
}

.testimonials-main-container .testimonials-carousal-main .testimonial .comment {
  font-size: 17px;
  /* color: var(--textGray70); */
  color: #666666;
  width: 80%;
}

.testimonials-main-container .testimonials-carousal-main .testimonial .name {
  font-size: 22px;
  /* color: var(--primaryText); */
  color: #333333;
}

.testimonials-main-container .testimonials-carousal-main .slick-dots {
  gap: 10px;
}

.testimonials-main-container .testimonials-carousal-main .slick-dots li button {
  background-color: var(--secondary);
  opacity: unset !important;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  margin: 0px;
}

.testimonials-main-container
  .testimonials-carousal-main
  .slick-dots
  .slick-active
  button {
  background-color: var(--primary) !important;
  outline: 2px solid var(--primary);
  outline-offset: 5px;
  margin: 0px;
}

/* **********************  contact support section style ********************** */

.contactSupport-main-container {
  height: auto;
  width: 100%;
  background-color: var(--bgSecondary);
  padding: 7rem 0px;
}

.contactSupport-main-container > div {
  width: 80%;
  margin: 0 auto;
}

.contactSupport-main-container > div .img {
  height: 350px;
  object-fit: cover;
  object-position: center;
}

.contactSupport-main-container .right-content-parent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 95%;
  margin-left: auto;
}

.contactSupport-main-container .right-content-parent .hdng {
  font-size: 35px;
  color: var(--primary);
}

.contactSupport-main-container .right-content-parent .discription {
  font-size: 17px;
  line-height: 26px;
  color: var(--primaryText);
  font-weight: 300;
}

.contactSupport-main-container .right-content-parent .btn {
  border-radius: 30px;
  padding: 15px;
  width: 220px;
  font-weight: 300;
  font-size: 18px;
  background-color: var(--bgWhite);
  color: var(--secondary);
  border: 1px solid var(--secondary);
}

/* **********************  claim fresh catch section style ********************** */

.claimFreshCatch-main-container {
  height: auto;
  width: 100%;
  background-color: var(--shadow);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 7rem 0px;
}

.claimFreshCatch-main-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  text-align: center;
  width: 70%;
}

.claimFreshCatch-main-container > div > .hdng {
  font-size: 35px;
  color: var(--primary);
}

.claimFreshCatch-main-container > div > .discription {
  font-size: 17px;
  line-height: 26px;
  color: var(--textGray70);
  font-weight: 400;
  width: 65%;
  margin: 0px;
}

.claimFreshCatch-main-container > div > .btn {
  border-radius: 30px;
  padding: 15px;
  width: 200px;
  margin-top: 20px;
  font-weight: 300;
}

.product-card-style-landing-page {
  margin: 0 auto !important;
}

@media only screen and (max-width: 1350px) {
  .categories-section-container .product-cards-parent {
    width: 100%;
  }
}

@media only screen and (max-width: 1050px) {
  .product-card-style-landing-page {
    min-width: 100% !important;
    max-width: 340px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .contactSupport-main-container .right-content-parent {
    margin: 0px auto;
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  /* **********************  Categories section style ********************** */

  .categories-section-container .hdng {
    font-size: 35px;
    line-height: 42px;
    width: 90%;
  }

  .categories-section-container .sub-hdng {
    font-size: 18px;
    line-height: 25px;
    width: 90%;
  }

  /* ********************** Why FrostFlow section style ********************** */

  .whyFrostFlow-sec-main .hdng {
    font-size: 35px;
    line-height: 42px;
    width: 90%;
  }

  /* **********************  Testimonial section style ********************** */

  .testimonials-main-container .hdng {
    font-size: 35px;
    line-height: 42px;
  }

  .testimonials-main-container
    .testimonials-carousal-main
    .testimonial
    .comment {
    width: 100%;
  }

  /* **********************  claim fresh catch section style ********************** */

  .claimFreshCatch-main-container > div {
    width: 90%;
  }

  .claimFreshCatch-main-container > div > .hdng {
    font-size: 30px;
  }

  .claimFreshCatch-main-container > div > .discription {
    font-size: 15px;
    line-height: 24px;
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  /* **********************  contact support section style ********************** */

  .contactSupport-main-container > div {
    width: 97%;
  }

  .contactSupport-main-container > div .img {
    width: 100%;
  }

  .contactSupport-main-container .right-content-parent .hdng {
    font-size: 30px;
  }

  .contactSupport-main-container .right-content-parent .discription {
    font-size: 15px;
    line-height: 24px;
  }

  /* **********************  Categories section style ********************** */

  .categories-section-container .hdng {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    width: 95%;
  }

  /* ********************** Why FrostFlow section style ********************** */

  .whyFrostFlow-sec-main .text {
    width: 90%;
  }

  /* **********************  claim fresh catch section style ********************** */

  .claimFreshCatch-main-container > div > .discription {
    width: 100%;
  }
}

.categories-section-container:has(.product-card-landing-unauth:hover) .explore-now-btn {
  /* margin-top: 174px; */
}

.outlined-btn {
  border-radius: 30px;
  padding: 15px;
  width: 220px;
  font-weight: 300;
  font-size: 18px;
  background-color: transparent;
  color: var(--primary);
  border: 1px solid var(--primary);
  /* margin: 44px 0px 0px 0px; */
}

.dflex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 576px) {
  .product-card-style-landing-page {
    min-width: 340px !important;
    max-width: 340px !important;
  }

}