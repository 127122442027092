.favorites-container {
  padding: 120px 90px;

  .no-addtocart {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    p {
      text-align: center;
      font-size: 14px;
      color: var(--textGray70);
    }
  }

  .hdng-btn-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
