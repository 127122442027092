.packing_delivery_main {
  background-color: var(--bgSecondary);
  padding: 120px 100px;
  @media (max-width: 768px) {
    padding: 120px 20px;
  }
}
.packing_delivery_main .packing_heading {
  border-bottom: 1px solid var(--textGray70);
  padding-bottom: 5px;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
}
.packing_delivery_main .packing_heading h1 {
  color: var(--textGray70);
  font-size: 18px;
}
.image_container {
  width: 50%;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.image_container .img {
  padding: 70px 20px;
  background-color: white;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.image_container .img img {
  width: 150px;
  @media (max-width: 440px) {
    width: 80px;
  }
}

.packing_description {
  padding-top: 10px;
}
.packing_description h3 {
  color: red;
  font-size: 16px;
}
.packing_description .content-text {
  padding-top: 5px;
}
.packing_description .content-text p {
  font-size: 12px;
  color: var(--textGray100);
  font-weight: 400;
  padding-bottom: 2px;
}
.padding_top {
  padding-top: 50px;
}
.packing_method {
  padding-top: 30px;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
}
.packing_method h3 {
  color: red;
  font-size: 16px;
  text-align: center;
}
.packing_method .packing_method_tips {
  padding-top: 10px;
}
.packing_method .packing_method_tips p {
  font-size: 12px;
  color: var(--textGray100);
  font-weight: 400;
  word-spacing: 2px;
  letter-spacing: 0.5px;
  line-height: 19.5px;
}
.packing_method .packing_method_tips p span {
  color: red;
}

/* faq */
.faq_problems {
  padding-top: 10px;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
}
.faq_problems p {
  color: rgb(80, 71, 71);
  font-size: 12px;
  padding-bottom: 10px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 19.5px;
}
.faq_problems .faq_answer {
  padding-bottom: 10px;
  padding-left: 5px;
}
