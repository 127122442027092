.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #f9f9f9; */
  background-color: var(--bgSecondary);
  padding: 17px 15px;
  border-radius: 8px;
  gap: 10px;

  @media (max-width: 641px) {
    flex-direction: column;
    gap: 10px;
  }
}

.item-details {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 641px) {
    width: 100%;
  }
}

.item-image {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  /* background-color: #e8efee; */
  background-color: var(--bgPrimary);
  object-fit: cover;
  border: 1px solid var(--textGray10);
}

.item-info {
  display: flex;
  flex-direction: column;
}

.item-name {
  font-size: 14px;
  font-weight: 400;

  @media (max-width: 438px) {
    font-size: 11px;
  }
}

.item-price {
  font-size: 12px;
  color: black;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 4px;
}
.item-price .info-icon {
  color: #969393;
  margin-top: 1px;
  font-size: 14px;
}
.item-price span {
  color: #474747;
  font-weight: 300;
}

.arrow {
  font-size: 14px;
  margin-left: auto;
  padding-left: 30px;
  font-weight: 300;
}

.item-actions {
  display: flex;
  align-items: center;
  gap: 56px;

  @media (max-width: 641px) {
    width: 100%;
    justify-content: space-between;
    gap: 10px;
  }
}

.card_quantity {
  margin-top: 0px;
}
.card_quantity .quantity_select {
  background-color: white;
}

.card_quantity .quantity-loading {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantity-number {
  font-size: 14px;
}

.item-total {
  font-size: 16px;
  font-weight: 700;
}

.delete-btn {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
