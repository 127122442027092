.ordersContainer {
  width: 100%;
  padding: 10px 50px 50px 50px;

  .breadcrumbBox {
    padding-inline: 20px;
  }

  .tableContainer {
    margin-top: 30px;
    width: 100%;

    .ant-table-wrapper .ant-table-thead {
    }
    .ant-table-wrapper .ant-table-thead > tr > th {
      background-color: transparent;
      border-bottom: 0.67px solid rgba(53, 53, 53, 0.2) !important;
      padding: 24px 16px;
      color: var(--textGray53);
      opacity: 0.5;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 22.4px;
      letter-spacing: -0.5px;
    }
    .ant-table-wrapper .ant-table-tbody > tr > td {
      color: rgba(53, 53, 53, 0.8);
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22.4px;
      letter-spacing: -0.5px;
      background-color: #fcfbfa;
      border-bottom: 0.67px solid rgba(53, 53, 53, 0.2);

      padding: 16px 10px;
    }

    .ant-table-wrapper
      .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      background-color: transparent;
    }

    .productImageWrapper {
      width: 72px;
      height: 72px;
      border-radius: 4px;
      background-color: var(--bgSecondary);
      border: 1px solid var(--border);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 2px;
      div {
        flex: 1 1 calc(33.33% - 2px);
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 33.33%;
        max-height: 33.33%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .category-names{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p{
        margin-right: 4px;
      }
    }

    .reOrderBtn {
      height: 35px !important;
      padding-inline: 20px;
      background-color: transparent;
      border: 0.59px solid var(--primary);
    }

    .priceBox {
      h2 {
        color: var(--textGray53);
        opacity: 0.8;
        font-size: 20px;
        font-weight: 500;
        line-height: 22.4px;
        letter-spacing: -0.5px;
      }
      p {
        color: var(--primaryBlue90);
        font-size: 10px;
        font-weight: 400;
        line-height: 22.4px;
        letter-spacing: -0.5px;
        text-align: left;
        text-decoration-line: underline;
        text-decoration-style: solid;
        cursor: pointer;
      }
    }
  }

  .orderDetailContainer {
    margin-top: 30px;
    width: 100%;
    padding-inline: 100;

    .wrapper {
      width: 100%;
      border: 1px solid #e4e7e9;
      border-radius: 4px;

      .header {
        border-bottom: 1px solid #e4e7e9;
        padding: 10px 20px;

        display: flex;
        justify-content: space-between;

        .headingText {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }

        .rating-support-btn-parent {
          display: flex;
          align-items: center;
          gap: 30px;

          .supportText {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #e57722;

            svg {
              margin-right: 10px;
            }
          }
        }
      }
    }

    .content-main {
      padding: 20px 20px 0 20px;

      .order-info-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 25px;
        background-color: var(--shadow);
        border-radius: 6px;
        .order-info {
          display: flex;
          flex-direction: column;
          gap: 8px;

          h3 {
            font-size: 23px;
            font-weight: 400;
            color: var(--primaryText);
          }

          p {
            font-size: 15px;
            font-weight: 400;
            color: var(--textGray70);
          }
        }
        .order-price-parent {
          display: flex;
          gap: 40px;
          align-items: center;
          .order-price {
            font-size: 30px;
            font-weight: 500;
            color: var(--primary);
          }
          .reOrderBtn {
            border-radius: 30px;
            /* border: 0.59px; */
            padding: 15px;
            width: 200px;
            font-weight: 300;
            background-color: var(--primary);
            color: var(--bgWhite);
          }
        }
      }

      .order-arrival {
        p {
          font-size: 17px;
          color: var(--textGray70);
          margin: 30px 0;

          span {
            color: var(--primaryText);
            font-weight: 500;
          }
        }
      }

      .steps-and-text-parent {
        border-color: var(--bgWhite);
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 25px 10px;
        border-radius: 17px;
        margin-bottom: 20px;

        .step-discription {
          font-size: 16px;
          color: var(--textGray70);
          margin-bottom: 0px;
        }
        .step-title {
          font-size: 20px;
          color: var(--primaryText);
          font-weight: 700;
          line-height: normal;
          margin-bottom: 5px;
        }

        .ant-steps-item-icon {
          width: 54px;
          height: 54px;
          line-height: 40px;
          font-size: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          position: relative;
          background-color: var(--disabled);
          color: yellow;
          border: none;
          .ant-steps-finish-icon,
          .ant-steps-icon {
            color: var(--bgWhite);
          }
        }

        .ant-steps-item-tail {
          padding: 0px;
          margin-inline-start: 60px;
          top: 24px;
          &::after {
            height: 5px;
            background-color: var(--disabled);
          }
        }

        .ant-steps-item-content {
          width: 155px;
        }

        .ant-steps-item-finish,
        .ant-steps-item-process {
          .ant-steps-item-icon {
            background-color: var(--secondary);
          }
          .ant-steps-item-tail {
            &::after {
              background-color: var(--secondary);
            }
          }
        }

        .ant-steps-item-process {
          .ant-steps-item-tail {
            &::after {
              background-color: var(--disabled);
            }
          }
        }

        .ant-steps-item-wait {
          .step-discription,
          .step-title {
            color: var(--placeholder);
          }
        }

        p {
          color: var(--textGray70);
          font-size: 16px;
          line-height: normal;
          margin-bottom: 20px;
        }
      }

      .order-activity-parent {
        padding: 30px 0px;
        border-top: 1px solid #e4e7e9;
        border-bottom: 1px solid #e4e7e9;
        h6 {
          font-size: 20px;
          color: var(--primaryText);
          margin-bottom: 30px;
        }

        .table-parent {
          padding: 20px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          border-radius: 17px;
        }
      }

      .order-product-parent {
        padding: 30px 0px;
        border-top: 1px solid #e4e7e9;
        border-bottom: 1px solid #e4e7e9;
        .hdng {
          font-size: 20px;
          color: var(--primaryText);
          margin-bottom: 30px;
          span {
            color: var(--textGray70);
          }
        }

        .product-imgNameDis-Parent {
          display: flex;
          align-items: center;
          gap: 20px;
          .product-img-parent {
            min-width: 95px;
            max-width: 95px;
            min-height: 95px;
            max-height: 95px;
            border-radius: 10px;
            background-color: var(--bgSecondary);
            border: 1px solid var(--border);
            background-position: center;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 99%;
              object-fit: cover;
              object-position: center;
            }
          }
          .product-name-dis-parent {
            display: flex;
            flex-direction: column;
            h6 {
              font-size: 16px;
              color: var(--secondary);
            }
            p {
              font-size: 14px;
              color: var(--textGray70);
            }
          }
        }

        .actions-parent {
          display: flex;
          .supportText {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: #e57722;

            svg {
              margin-right: 10px;
            }
          }
          .download-receipt-btn {
            border-radius: 5px;
            padding: 5px;
            height: auto;
            border-color: var(--secondary);
            color: var(--secondary);
          }

          .leaveRatingBtn {
            background-color: transparent;
            border: none;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            color: #e57722;
            display: flex;
            align-items: center;
            cursor: pointer;
            svg {
              margin-left: 10px;
              font-size: 20px;
            }
          }
        }
      }

      .billingDetail-and-orderNotes-parent {
        padding: 30px 0px;
        border-top: 1px solid #e4e7e9;
        border-bottom: 1px solid #e4e7e9;

        .hdng {
          font-size: 20px;
          color: var(--primaryText);
          margin-bottom: 30px;
        }

        .row {
          .col1 {
            border-right: 1px solid var(--border);
          }
          .col2 {
            border-left: 1px solid var(--border);
          }
        }

        .billingAddress-parent {
          width: 90%;
          margin: 0 auto 0 0;

          div {
            display: flex;
            flex-direction: column;
            gap: 20px;
            h6 {
              font-size: 16px;
            }

            p {
              font-size: 16px;
              line-height: 23px;
              color: var(--textGray70);

              span {
                color: var(--primaryText);
              }
            }
          }
        }

        .order-notes-parent {
          width: 90%;
          margin: 0 0 0 auto;
          .note {
            font-size: 16px;
            color: var(--textGray70);
            margin-bottom: 20px;
            font-weight: 400;
            line-height: 23px;
          }
        }
      }
    }
  }
}
