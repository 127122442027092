.verifyNumberAndEmailModal {
  width: 656px !important;
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0px;
    border-radius: 1px;
    box-shadow: 0px 0px 25px 0px #CCC;
    .ant-modal-body {
      width: 380px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .heading {
        color: var(--textGray100);
        font-size: 29px;
        font-weight: 700;
        line-height: 34.8px;
        margin-bottom: 9px;
      }
      .description {
        color: var(--textGray70);
        font-size: 16px;
        margin-bottom: 54px;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
      .inputContainer{
        width: 346px;
      }

      .footer {
        margin-top: 48px;
        display: flex;
        gap: 24px;
      }
    }
  }
}
