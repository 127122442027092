.input-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--bgWhite);
  border-radius: 30px;
}

.input-container:focus-within label {
  transform: translate(0, 8px) scale(0.6);
  color: #0a53e4;
}

.input-container .filled {
  transform: translate(0, 10px) scale(0.6);
  font-size: 12px;
  line-height: 15.333px;
  letter-spacing: -0.16px;
  color: var(--textGraySand);
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 12px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: var(--textGray10);
  font-size: 14px;
  left: 20px;
  font-family: "Inter";
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.28px;

  @media (max-width: 528px) {
    font-size: 12px !important;
  }
}

.input-container input {
  height: 48px;
  border-radius: 100px;
  border: none;
  padding: 12px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  outline: none;
  font-family: "Inter";
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border: 1px solid var(--textGraySand);
  color: var(--textGray100);
}

.input-container input:focus {
  background-color: var(--bgPrimary) !important;
  border: 1px solid var(--constantPrimary) !important;
}

/* Valid input styles */
.input-container input.valid {
  background-color: var(--bgPrimary);
  border: 1px solid var(--textGray100);
  padding: 23px 15px 12px 15px;
}
.input-container label.valid {
  color: var(--textGraySand);
}
/* Invalid input styles */
.input-container input.invalid {
  background-color: var(--bgWhite);
  border: 1px solid var(--border);
  padding: 23px 15px 12px 15px;
}

.input-container .required {
  color: #ff4d4f;
}

.ant-form-item-has-error input {
  border: 1px solid #ff4d4f;
}
.ant-form-item-has-error input:focus {
  border: 1px solid #ff4d4f;
}

.toggle-password {
  position: absolute;
  top: 30%;
  font-size: 17px;
  right: 10px;
}
