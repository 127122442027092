@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.cdnfonts.com/css/cabinet-grotesk");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  /* font-family: "Cabinet Grotesk", "Roboto", sans-serif; */
  /* font-family: "Cabinet Grotesk", sans-serif; */
}

body {
  /* min-width: 360px;
  max-width: fit-content; */
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  font-style: normal;
}

.pi55 {
  padding-inline: 55px;
}
/* styles for react phone input */
.react-tel-input {
  width: 100% !important;
  position: relative !important;
  display: flex;
  flex-direction: column;
  background-color: var(--bgWhite);

  border-radius: 30px;
}
.react-tel-input.phoneInputValid input {
  background-color: var(--bgPrimary) !important;
  border: 1px solid var(--borderBlack) !important;
}

.react-tel-input input:focus {
  background-color: var(--bgPrimary);
  border: 1px solid var(--constantPrimary) !important;
}

.react-tel-input .special-label {
  transform: translate(0, 8px) scale(0.6);
  font-size: 16px !important;
  left: 5px !important;
  top: -5px !important;
  background: transparent !important;
}

.react-tel-input:has(.form-control:focus) .special-label {
  color: #0a53e4 !important;
}

.react-tel-input input {
  height: 50px !important;
  width: 100% !important;
  border-radius: 4px !important;
  border: none !important;
  padding: 25.5px 14px 18.5px 65px !important;
  font-size: 16px !important;
  line-height: 1 !important;
  outline: none !important;
  box-shadow: none !important;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  border-radius: 30px !important;
  border: 1px solid var(--border) !important;
}
.selected-flag {
  padding-left: 19px !important;
}

.react-tel-input .selected-flag .arrow {
  border: none !important;
  top: 5px !important;
  padding-left: 42px !important;
  left: -13px !important;
}

.react-tel-input .selected-flag .arrow::before {
  content: "";
  display: inline-block;
  width: 15px; /* Adjust size as needed */
  height: 15px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'%3E%3C/path%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.breadcrumb-seperator {
  svg {
    font-size: 12px !important;
  }
}

.ant-breadcrumb li:last-child {
  color: rgb(52 87 182 / 88%);
}

.react-tel-input .flag-dropdown {
  top: 10px !important;
}

.cursor-pointer{
  cursor: pointer !important;
}