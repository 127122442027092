.attributes_data{
    width: 85%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.attributes_data h3{
font-weight: 400;
}
.attributes_data table{
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
}