.cardsContainer {
  width: 100%;
  padding: 10px 50px 50px 50px;

  .breadcrumbBox {
    padding-inline: 20px;
  }

  .cards-content {
    margin-top: 30px;
    width: 100%;
    padding-inline: 100;

    .wrapper {
      width: 100%;
      border: 1px solid #e4e7e9;
      border-radius: 4px;

      .header {
        border-bottom: 1px solid #e4e7e9;
        padding: 10px 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .headingText {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-transform: uppercase;
        }

        .addCard-btn {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          color: #e57722;
          display: flex;
          align-items: center;
          border: none;
          padding: 05px;
          margin: 0px;
          height: auto;

          svg {
            font-size: 16px;
          }
        }
      }

      .cards-parent-row {
        padding: 20px;
        display: flex;
        width: auto;
        margin: 0 auto;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
        .card {
          height: 230px;
          width: 350px;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 35px 25px;
          .amount-actions-parent {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .amount {
              font-size: 26px;
              font-weight: 500;
              color: var(--bgWhite);
              span {
                font-weight: 300;
              }
            }
            svg {
              font-size: 36px;
              color: var(--bgWhite);
              cursor: pointer;
            }
          }

          .card-number-parent {
            label {
              color: var(--placeholder);
              font-size: 20px;
              font-weight: 300;
            }
            .card-number {
              color: var(--bgWhite);
              font-size: 24px;
              font-weight: 400;
              margin-top: 5px;
              svg {
                color: var(--placeholder);
                margin-left: 15px;
              }
            }
          }

          .cardType-userName-parent {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 0px;
            .card-type-icon {
              color: var(--bgWhite);
              font-size: 65px;
            }
            h6 {
              font-size: 23px;
              color: var(--bgWhite);
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}
