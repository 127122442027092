.modalWrapper {
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease, visibility 0.3s ease;

  .modalBody {
    padding: 20px 24px;
    border-radius: 8px;
    background-color: var(--bgModal);
    position: relative;
    transform: scale(0.9);
    transition: transform 0.3s ease;

    .closeModal {
      position: absolute;
      width: 32px;
      height: 32px;
      top: 10px;
      right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 4px;
      transition: all 0.3s ease;
      border: none;
      outline: none;
      background-color: transparent;

      svg {
        color: rgba(0, 0, 0, 0.45);
      }
    }
    .closeModal:hover {
      background-color: rgba(0, 0, 0, 0.06);

      svg {
        color: var(--primaryText);
      }
    }

    .modalTitle {
      width: calc(100% - 20px);
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 16px;
      color: var(--primaryText);
      line-height: 1.5;
    }

    .modalContent {
      width: 100%;
    }
  }
}

.showModal {
  opacity: 1;
  visibility: visible;
}

.showModal .modalBody {
  transform: scale(1);
}
