.product-card {
  height: fit-content;
  min-width: 340px;
  max-width: 340px;
  background-color: var(--bgWhite);
  border-radius: 5px;
  border: 1px solid var(--border);
  padding: 20px;
  cursor: pointer;
  position: relative;
}

.product-card .product-img-parent {
  width: 100%;
  height: 300px;
  background-color: var(--shadow);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.product-card .product-img-parent .product-img {
  height: 160px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.product-card .product-info-parent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: end;
  flex: 1;
  position: relative;
}

.product-card .product-info-parent .product-name {
  margin: 16px 0px 8px 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--textGray100);
}

.product-card .product-info-parent .product-discription {
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--textGray100);
}

.product-card .product-info-parent .product-weight_categoryText-parent {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* gap: 10px; */
  margin-top: 16px;
  color: var(--primaryText);
}

.product-card .product-info-parent .product-weight_categoryText-parent > p {
  padding-right: 8px;
  border-right: 1px solid var(--textGray70);
  font-size: 12px;
  font-weight: 500;
  color: var(--textGray70);
}

.product-card
  .product-info-parent
  .product-weight_categoryText-parent
  > p:nth-of-type(2) {
  padding: 0px 8px;
}

.product-card
  .product-info-parent
  .product-weight_categoryText-parent
  .stockTag {
  margin-left: 12px;
  border-width: 1px;
  border-radius: 20px;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 4px 8px;
}

.product-card
  .product-info-parent
  .product-weight_categoryText-parent
  .instockTag {
  background-color: #e8fcf2;
  color: #0ada72;
  border-color: #0ada72;
}

.product-card
  .product-info-parent
  .product-weight_categoryText-parent
  .outOfStockTag {
  background-color: #ffe1cc;
  color: red;
  border-color: red;
}

.product-card
  .product-info-parent
  .product-weight_categoryText-parent
  .limitedStockTag {
  background-color: #ffe1cc;
  color: #f26500;
  border-color: #f26500;
}

.product-card:hover {
  border-color: var(--primary);
  border-bottom: none;
}
.border-show:hover {
  border-color: var(--primary);
  border-bottom: 1px solid var(--primary) !important;
}

.product-card .fav-and-view-btn-parent {
  display: none;
  flex-direction: column;
  width: auto;
  height: auto;
  gap: 10px;
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 1;
}

.product-card .fav-and-view-btn-parent > span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 50%;
  background-color: var(--bgWhite);
  margin-top: 48px;
}

.product-card .fav-and-view-btn-parent > span > .icn {
  font-size: 24px;
  color: var(--primaryText);
}

.product-card .favProduct-heart_icon {
  display: none;
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.product-card .favProduct-heart_icon > .icn {
  color: var(--primaryText);
  font-size: 24px;
  vertical-align: middle;
}

.product-card:hover .favProduct-heart_icon {
  display: block;
}

.product-card .visible-icon {
  display: block;
}

.product-card .fav-action-loading {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 10;
}

.product-card .detail-login-buttons-parent {
  display: none;
  flex-direction: column;
  width: calc(100% - -2px);
  position: absolute;
  height: fit-content;
  left: -1px;
  background-color: var(--bgWhite);
  z-index: 1;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom: 1px solid var(--primary);
  border-right: 1px solid var(--primary);
  border-left: 1px solid var(--primary);
  padding: 0px 20px 20px 20px;
  gap: 25px;
}

.product-card .detail-login-buttons-parent > .login-btn {
  border-radius: 30px;
  padding: 13px;
  width: 100%;
  margin: 0px;
  font-weight: 300;
  font-size: 14px;
  background-color: var(--bgWhite);
  color: var(--primary);
  border: 1px solid var(--primary);
  white-space: nowrap;
}

.product-card .detail-login-buttons-parent > .view-details-btn {
  border-radius: 30px;
  font-size: 14px;
  font-weight: 300;
  padding: 13px;
  white-space: nowrap;
}

.product-card:hover .detail-login-buttons-parent,
.product-card:hover .fav-and-view-btn-parent {
  display: flex;
}

.product-price {
  padding: 10px 0px;
}
.group-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  padding: 10px 0px;

  .add-to-cart-btn {
    flex: 1;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 300;
    background-color: var(--bgWhite);
    color: var(--primary);
    border: 1px solid var(--primary);
    white-space: nowrap;
  }
  .buy-now-btn {
    flex: 1;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 300;
    white-space: nowrap;
  }
}

/* .product-card-style-landing-page:hover {
  border: 1px solid var(--primary);
}

.product-card-style-landing-page .detail-login-buttons-parent {
  border: none;
  padding: 20px 0px 0px 0px;
  position: static;  
} */

/* modal verify */
.verify-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 40px;
}
.verify-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;

  .heading {
    font-size: 24px;
    font-weight: 600;
  }
  .sometext {
    color: var(--textGray70);
    line-height: 1.5;
    font-size: 14px;
  }

  .verify_btn {
    width: 82%;
    border-radius: 20px;
    font-weight: 300;
  }
  .verify_btn_nobg {
    background-color: transparent;
    border: none;
    color: var(--primary);
    font-weight: 300;
    font-size: 14px;
  }
}

