.invoice-main-container {
  padding: 20px;
}

.invoice-main-container .info-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background-color: var(--bgPrimary);
  border-radius: 10px;
}

.invoice-main-container .info-section .right-content {
  display: flex;
  flex-direction: column;
  gap: 45px;
}

.invoice-main-container .info-section .right-content > .heading {
  font-size: 40px;
  font-weight: 600;
  color: var(--primaryText);
  margin: 0px;
}

.invoice-main-container .info-section .right-content > .billedTo-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.invoice-main-container .info-section .right-content > .billedTo-info > h6 {
  font-size: 22px;
  font-weight: 400;
  color: var(--textGray70);
}

.invoice-main-container .info-section .right-content > .billedTo-info > h5 {
  font-size: 26px;
  font-weight: 600;
  color: var(--primaryText);
}

.invoice-main-container .info-section .right-content > .billedTo-info > p {
  font-size: 19px;
  font-weight: 400;
  color: var(--primaryText);
}

.invoice-main-container .info-section .left-content {
  display: flex;
  flex-direction: column;
  gap: 45px;
}

.invoice-main-container .info-section .left-content > div {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 6px;
}

.invoice-main-container .info-section .left-content > div > h6 {
  font-size: 19px;
  font-weight: 400;
  color: var(--textGray70);
}

.invoice-main-container .info-section .left-content > div > h5 {
  font-size: 26px;
  font-weight: 600;
  color: var(--primaryText);
  margin-top: 2px;
}

.invoice-main-container .info-section .left-content > div > p {
  font-size: 18px;
  font-weight: 400;
  color: var(--primaryText);
  margin-bottom: 13px;
}

.invoice-main-container .table-section-wrapper {
  padding: 14px;
  margin-top: 20px;
  margin-bottom: 50px;
  position: relative;
}

.invoice-main-container .table-section-wrapper .ant-table-thead {
  height: 70px;
}

.invoice-main-container
  .table-section-wrapper
  .ant-table-thead
  > tr
  > .ant-table-cell {
  vertical-align: top;
}

.invoice-main-container
  .table-section-wrapper
  .ant-table-thead
  > tr
  > th,
.invoice-main-container
  .table-section-wrapper
  .ant-table-tbody
  > tr
  > td,
.invoice-main-container
  .table-section-wrapper
  .ant-table-summary
  > tr
  > td {
  border: none;
  background-color: transparent;
  padding: 7px;
}

.invoice-main-container
  .table-section-wrapper
  .ant-table-thead
  > tr
  > th::before {
  display: none;
}

.invoice-main-container
  .table-section-wrapper
  .ant-table-thead
  > tr
  > th,
.invoice-main-container
  .table-section-wrapper
  .ant-table-tbody
  > tr
  > td {
  font-size: 18px;
  font-weight: normal;
  color: var(--textGray70);
}

.invoice-main-container
  .table-section-wrapper
  .ant-table-thead
  > tr
  > th:first-child
  .table-hdng {
  color: var(--primaryText);
  font-weight: bold;
  font-size: 25px;
}

.invoice-main-container
  .table-section-wrapper
  .ant-table-tbody
  .text-black {
  color: var(--primaryText);
}

.invoice-main-container
  .table-section-wrapper
  .ant-table-tbody
  > tr:last-child
  > td {
  border-bottom: 2px solid var(--bgSecondary);
  padding-bottom: 35px;
}

.invoice-main-container
  .table-section-wrapper
  .ant-table-summary
  > tr
  > td {
  padding: 23px 0px 0px 0px;
}

.invoice-main-container
  .table-section-wrapper
  .ant-table-summary
  > tr
  > td {
  font-size: 18px;
  font-weight: normal;
  color: var(--textGray70);
  text-align: right;
}

.invoice-main-container
  .table-section-wrapper
  .ant-table-summary
  > tr
  > td
  .semi-hdng {
  font-weight: 600;
}

.invoice-main-container
  .table-section-wrapper
  .ant-table-summary
  > tr:last-child
  > td {
  border-bottom: 2px solid var(--bgSecondary);
  padding-bottom: 100px;
}

.invoice-main-container .table-section-wrapper .total-price-parent {
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  gap: 17rem;
  background-color: var(--shadow);
  padding: 0px 30px;
  height: 100px;
  width: fit-content;
  position: absolute;
  bottom: -35px;
  right: 0;
  z-index: 10;
  border-radius: 8px;
  backdrop-filter: blur(50px);
}

.invoice-main-container
  .table-section-wrapper
  .total-price-parent
  > h6 {
  font-size: 23px;
  font-weight: 400;
  color: var(--textGray70);
}

.invoice-main-container
  .table-section-wrapper
  .total-price-parent
  > h6
  > span {
  color: var(--primaryText);
}

.invoice-main-container
  .table-section-wrapper
  .total-price-parent
  > h5 {
  font-size: 33px;
  font-weight: 600;
  color: var(--primaryText);
}

.invoice-main-container .footer-section{
  margin-top: 10rem;
}

.invoice-main-container .footer-section .row .column1{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.invoice-main-container .footer-section .row .column1 .logo{
  width: 260px;
  object-fit: cover;
  object-position: center;
}

.invoice-main-container .footer-section .row .column1 .name{
  font-size: 30px;
  font-weight: 700;
  color: var(--primaryText);
}

.invoice-main-container .footer-section .row .column1 .address{
  font-size: 20px;
  font-weight: 400;
  color: var(--primaryText);
}

.invoice-main-container .footer-section .row .column1 .mail{
  font-size: 20px;
  font-weight: 400;
  color: var(--primaryBlue100);
}

.invoice-main-container .footer-section .row .column1 span > p:first-child{
  font-size: 20px;
  font-weight: 400;
  color: var(--textGray70);
}

.invoice-main-container .footer-section .row .column1 span > p:last-child{
  font-size: 20px;
  font-weight: 400;
  color: var(--primaryText);
}

.invoice-main-container .footer-section .row .column2{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.invoice-main-container .footer-section .row .column2 > h6{
  margin-top: 25px;
  font-size: 27px;
  font-weight: 500;
  color: var(--primaryText);
}

.invoice-main-container .footer-section .row .column2 > p{
  font-size: 22px;
  font-weight: 400;
  color: var(--textGray70);
}
