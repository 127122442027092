.primaryButton {
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: var(--primary);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: var(--primaryHover);
  }

  &:active {
    background-color: var(--primaryActive);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}

.waveEffect {
  position: absolute;
  width: 10px;
  height: 10px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  pointer-events: none;
  animation: waveAnimation 0.6s ease-out;
  transform: scale(0);
}

@keyframes waveAnimation {
  0% {
    opacity: 0.6;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(10);
  }
}
