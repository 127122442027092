.checkout_info_main {
  .information_body {
    padding: 30px 100px;

    h3 {
      font-size: 26px;
      font-weight: 500;
    }

    .information_container {
      display: flex;
      gap: 20px;
      padding: 30px 0px;

      .left-side {
        flex: 3;

        .collapse {
          background-color: transparent;
          border: none;
          .ant-collapse-item {
            border-top: 1px solid #d9d9d9;
            border-bottom: none;
            .ant-collapse-header {
              padding: 28px 0px;

              .shipping_collapse_header {
                display: flex;
                cursor: pointer;
                justify-content: space-between;
                align-items: center;
                .title {
                  font-size: 18px;
                  font-weight: 500;
                  display: flex;
                  gap: 12px;
                  align-items: center;
                }
                .shipping_info {
                  p {
                    font-size: 14px;
                    font-weight: 300;
                    color: var(--textGray100);
                  }
                }
              }
            }
            .ant-collapse-content {
              .ant-collapse-content-box {
                padding: 0px;
                .shipping-expand-container {
                  padding: 23px 0px 50px 30px;
                  display: flex;
                  flex-direction: column;
                  gap: 20px;
                  .note-text {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.8px;
                    svg {
                      font-size: 20px;
                    }
                  }

                  .radio-btns-group {
                    display: flex;
                    flex-direction: column;
                  }

                  .shipping-address-content-container {
                    .shipping-address-parent {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .address-checkbox {
                        .address-text-parent {
                          display: flex;
                          flex-direction: column;
                          gap: 5px;
                          padding-left: 32px;
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 16.8px;
                          color: var(--textGray100);
                        }
                      }
                    }
                  }

                  .add_shipping_address_btn {
                    text-decoration: underline;
                    color: var(--primary);
                    cursor: pointer;
                    width: fit-content;
                    background-color: transparent;
                    padding: 0px;
                    span:nth-of-type(2) {
                      font-size: 14px !important;
                      margin-left: 32px;
                    }
                  }

                  @media (max-width: 625px) {
                    padding-left: 16px;
                  }
                }
              }
            }
          }
          .ant-collapse-item:last-child {
            border-bottom: 1px solid #d9d9d9 !important;
          }
        }
      }

      @media (max-width: 1024px) {
        flex-direction: column;
      }
    }

    @media (max-width: 786px) {
      padding: 10px 20px;
    }
  }
}

.payment_method {
  cursor: pointer;
}

.payment_method .text {
  font-size: 18px;
  font-weight: 500;
}
.payment_section {
  padding-left: 96px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 625px) {
    padding-left: 16px;
  }
}
.add_card {
  display: flex;
  align-items: center;
  cursor: pointer;

  .add_card_icon {
    display: flex;
  }

  @media (max-width: 425px) {
    flex-direction: column;
  }
}
.add_card p {
  padding-right: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.select_payment {
  display: flex;
  align-items: center;
  gap: 10px;

  .payment_icons {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  @media (max-width: 425px) {
    flex-direction: column;
  }
}
.select_payment .radio {
  font-size: 18px;
}
.delivery_type {
  padding-right: 40px;

  @media (max-width: 405px) {
    padding-right: 0px;
  }
}
.on-delivery {
  justify-content: space-between;
  cursor: pointer;

  @media (max-width: 728px) {
    flex-direction: column;
  }
}
.on-delivery div {
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 405px) {
    flex-direction: column;
  }
}
.btn_outline {
  border: 1px solid black;
}
.btn_outline:hover {
  background-color: black;
  color: white;
}

.item_delivery_option {
  padding-left: 50px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 1024px) {
    padding-left: 1px;
  }
}

.delivery_date {
  border: 1px solid var(--bgSecondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 5px;
}
.delivery_date p:first-child {
  font-weight: 500;
  font-size: 14px;
}

.delivery_date p:first-child span {
  font-weight: 300;
}
.pay_btn {
  width: 100%;
  margin-top: 16px;
}

/* card title */

.payment-modal {
  .ant-modal-content {
    padding: 50px 30px;
    border-radius: 1px;
    box-shadow: 0px 0px 25px 0px #ccc;
    .ant-modal-body {
      min-height: 350px;
      max-height: fit-content;
      display: flex;
      flex-direction: column;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .submit-btn {
      width: auto;
      margin: 30px auto 0px auto;
    }
  }

  .payment-process-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    gap: 20px;
    p {
      font-size: 20px;
      font-weight: 700;
      color: var(--primary);
    }
  }
}

.shipping_change_btn {
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;
  font-size: 14px;
  width: fit-content;
  background-color: transparent;
  border: none;
  padding: 0px;
}

.shipping_change_btn:hover {
  background-color: transparent;
}
