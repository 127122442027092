.authHeading {
  color: var(--textGray100);
  font-size: 29px;
  font-weight: 700;
  line-height: 34.8px;
  text-align: center;
  margin-bottom: 10px;

  font-family: "Inter" !important;
  @media (max-width: 425px) {
    font-size: 26px;
  }
  @media (max-width: 375px) {
    font-size: 22px;
  }
}

.authHeading-mb {
  margin-bottom: 59px;
}

.authDescription {
  margin-bottom: 59px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  /* text-align: center; */
  color: var(--textGray70);
  display: flex;
  gap: 10px;

  span {
    color: var(--primaryBlueText);
    cursor: pointer;
    /* padding-left: 22px; */

    &:hover {
      color: var(--secondary);
    }
  }

  @media (max-width: 25px) {
    font-size: 14px;
  }
  @media (max-width: 375px) {
    font-size: 10px;
  }
}

.authSubText {
  color: var(--textGray70);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.22px;
  text-align: center;
}

/* Sign In */
.signinContainer {
  width: 380px;

  padding-block: 117px;

  .authDescription {
    padding-left: 0px;
  }

  .signinForm {
    width: 346px;
    .ant-form-item {
      margin-bottom: 20px;
    }
    .ant-form-item:last-child {
      margin-bottom: 0px;
    }
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
    }

    @media (max-width: 445px) {
      width: 100%;
    }
  }

  .rememberAndForgot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-form-item {
      margin-bottom: 30px;
    }
    button {
      color: var(--secondary);
      height: fit-content;
      margin-bottom: 30px;

      span {
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--primaryBlue90) !important;
    }
    .ant-checkbox + span {
      font-family: "Inter";
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.24px;
      text-align: left;
      color: var(--textGray50);
      padding-left: 8px;
    }
  }
  .textWithDivider {
    padding-top: 50px;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--textGray101);
  }

  section {
    width: 100%;
    padding-inline: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section2 {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}

/* Code Verification */
.codeVerificationContainer {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding-block: 119px;

  .codeDescription {
    font-family: "Inter";
    font-size: 14px;
    margin-top: 10px;
    font-weight: 400;
    line-height: 20.22px;
    text-align: center;
    color: var(--textGray70);
  }

  .codeText {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 700;
    line-height: 20.22px;
    text-align: center;
    margin-bottom: 50px;
    color: var(--textGray70);
  }

  .codeVerificationForm {
    .ant-form-item {
      margin-bottom: 27px;
    }
    .ant-form-item:last-child {
      margin-bottom: 0px;
    }
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
    }
  }

  section {
    width: 100%;
    padding-inline: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .links {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;

      margin-top: 74px;

      p {
        font-size: 14px;
        color: var(--primaryBlue90);
        cursor: pointer;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20.22px;

        &:hover {
          color: var(--secondary);
        }
      }
    }
  }
}

/* Welcome Back */
.welcomeBackContainer {
  width: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-block: 100px;

  .welcomeText {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 60px;
    width: 472px;
    color: var(--textGray70);

    @media (max-width: 520px) {
      width: 100%;
    }
  }
  .additionalButtons {
    display: flex;
    flex-direction: column;

    .modifyButton,
    .returnButton {
      border: none;
    }
  }
}
.recurringOrderOption {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Sign Up */
.signupContainer {
  width: 380px;
  padding-block: 119px;

  display: flex;
  flex-direction: column;
  align-items: center;

  section {
    display: flex !important;
    width: 100%;
    margin-top: 48px;
    padding-inline: 30px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
}

/* Create Account */
.createAccountContainer {
  min-width: 380px;
  max-width: fit-content;

  padding: 119px 0px;

  .signinForm {
    .ant-form-item {
      margin-bottom: 20px;
    }
    .ant-form-item:last-child {
      margin-bottom: 0px;
      margin-top: 10px;
    }
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
    }
  }

  section {
    width: 100%;
    padding-inline: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    Form {
      max-width: 346px !important;

      .ant-checkbox + span {
        color: var(--textGray50);
        /* font-family: Inter; */
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }

  .textWithDivider {
    padding-top: 50px;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--textGray101);
  }

  .section2 {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  @media (max-width: 425px) {
    padding-block: 50px;
  }
}

/* Account Security */
.accountSecurityContainer {
  padding-block: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .color-hdng {
    color: var(--primaryBlue90);
  }

  .typeDivider {
    width: 400px;
    margin-top: 87px;
    margin-bottom: 82px;

    @media (max-width: 640px) {
      margin-top: 50px;
      margin-bottom: 60px;
    }
    @media (max-width: 475px) {
      width: 200px;
    }
  }

  button {
    padding-left: 74px;
    padding-right: 74px;
  }

  .authentication {
    display: flex;
    gap: 56px;

    div {
      padding: 55px 30px 43px 35px;
      width: 270px;
      border: 1px solid var(--borderGray);
      border-radius: 4px;
      position: relative;

      h2 {
        font-family: "Inter";
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: -0.48px;
        color: var(--borderGray);
        padding-bottom: 5px;
      }
      p {
        color: var(--borderGray);
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }

    .checkIcon {
      display: none;
      position: absolute;
      color: var(--primaryBlue90);
      top: 10px;
      right: 10px;
    }

    .selected {
      border: 1px solid var(--primaryBlue90);
      h2 {
        color: var(--primaryBlue90);
      }
      p {
        color: var(--primaryBlue90);
      }
    }

    .active {
      background-color: var(--bgPrimary);
      border: 1px solid var(--primaryBlue90);

      h2 {
        color: var(--primaryBlue90);
      }
      p {
        color: var(--primaryBlue90);
      }
    }
    .active .checkIcon {
      display: flex;
    }

    @media (max-width: 640px) {
      flex-direction: column;
    }
  }

  .activateText {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: var(--textGray70);
    margin-top: 93px;
    margin-bottom: 36px;

    @media (max-width: 495px) {
      margin-top: 68px;
      padding: 0px 61px;
      text-align: center;
    }
  }

  .skipText {
    margin-top: 20px;
    color: var(--secondary);
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px;
  }

  @media (max-width: 640px) {
    padding-block: 50px;
  }
}

/* Create New Password */
.createNewPasswordContainer {
  width: 320px;
  padding-block: 119px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .authDescription {
    margin-bottom: 70px;
  }

  .createNewPasswordForm {
    .ant-form-item {
      margin-bottom: 20px;
    }
    .ant-form-item:last-child {
      margin-bottom: 0px;
    }
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
    }

    button {
      margin-top: 10px;
    }
  }
}

.passwordStrengthBox {
  margin-bottom: 30px;
  .passwordStrength {
    display: flex;
    gap: 10px;
    height: 6px;

    div {
      background-color: var(--bgSecondary);
      height: 6px;
      flex: 1;
      border-radius: 10px;
    }

    .active {
      background-color: #1bcc00 !important;
    }
  }
  .textBox {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;

    p {
      font-size: 14px;
    }
  }
}

/* Verification Form */
.verificationFormContainer {
  width: 320px;
  padding-block: 119px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .authDescription {
    padding-left: 0px;
  }

  .verificationForm {
    .ant-form-item {
      margin-bottom: 40px;
    }
    .ant-form-item:last-child {
      margin-bottom: 0px;
    }
  }
}

/* Verification Processes */
.bgPrimary {
  background-color: var(--bgBlue) !important;
}

/* Verification Processes */
.verificationProcessesContainer {
  /* width: 320px; */
  padding-block: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .authHeading {
    color: #fff;
    font-family: "Inter";
    font-size: 29px;
    font-weight: 700;
    line-height: 34.8px;
    text-align: center;
  }

  .descriptionText {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin: 10px 0px;
    color: var(--btnPrimary);
  }

  .cardBox {
    margin-block: 80px;
    padding-inline: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 80px;
    width: 100%;

    @media (max-width: 935px) {
      padding-inline: 50px;
    }
    @media (max-width: 535px) {
      padding-inline: 20px;
    }
  }

  .nextBtn {
    background-color: #fff;
    color: var(--primaryBlueText);
  }

  .doThisLater {
    color: var(--btnPrimary);
    margin-top: 32px;
    font-size: 14px;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
  }
}

/* Reset Password */
.resetPassOptContainer {
  width: 380px;
  display: flex;
  align-items: center;
  flex-direction: column;

  padding-block: 119px;

  .authSubText {
    margin-top: 10px;
    margin-bottom: 37px;
  }

  .radioGroup {
    margin-bottom: 70px;

    .ant-radio-group {
      display: flex;
      gap: 40px;

      label {
        margin-inline-end: 0px;

        span {
          color: var(--textGray70);
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20.22px;
          text-align: center;
        }
      }
    }
  }

  > button {
    margin-top: 30px;
  }
}

/* Reset Password Option */
.checkEmailContainer {
  width: 420px;
  display: flex;
  align-items: center;
  flex-direction: column;

  padding-block: 119px;

  .authSubText {
    margin-bottom: 50px;

    span {
      color: var(--primaryBlue90);
      cursor: pointer;
      padding-left: 20px;
    }
  }
  .authSubText:last-child {
    margin-bottom: 0px;
  }
}

/* Verify Code Page */
.verifyCodePageContainer {
  padding-block: 100px;
}

/* Thank You */
.thankYouContainer {
  width: 380px;
  padding-block: 119px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .authSubText {
    margin: 50px 0px 45px 0px;
  }
}
