/* Button */
.ant-btn-primary {
  border-radius: 100px;
  box-shadow: none;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  padding: 12px 55px;

  height: 48px;
  color: var(--btnPrimary);
}

.ant-btn-default {
  border-radius: 30px;
  box-shadow: none;
  border: 1px solid var(--constantPrimary);
  color: var(--constantPrimary);

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  padding: 12px 24px;

  height: 48px;
}
/* card border */
.ant-card-bordered{
  border: none !important;
}

/* pagination */
.ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-next .ant-pagination-item-link{
display: none !important;
}
.ant-pagination .ant-pagination-prev{
  display: none;
}
.ant-pagination .ant-pagination-item {
  display: inline-block;
  background-color: #f3f3f3;
  border: 1px solid transparent;
  border-radius: 50%;
 
}

/* SELECTOR */


/* DRAWER MODAL */
.ant-drawer-right>.ant-drawer-content-wrapper{
  width: 430px !important;
}
.ant-drawer .ant-drawer-title{
  font-size: 30px;
}
.ant-drawer .ant-drawer-close{
  color: black;
  font-size: 22px;
}
.ant-drawer .ant-drawer-header{
  border-bottom: none;
}

.ant-drawer .ant-drawer-header-title {
  display: flex;
  flex-flow: row-reverse;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  min-height: 0;
  font-size: 30px;
}
.ant-drawer .ant-drawer-body::-webkit-scrollbar {
  width: 5px;
}

/* modal */
.ant-modal .ant-modal-body {
  padding-top: 15px;
}
.ant-modal .ant-modal-content {
  padding: 30px 35px;
  padding-bottom: 1px;
}


/* RADIO */

/* Style for the radio button (default state) */
 .ant-radio-wrapper .ant-radio-inner {
  border-color: var(--secondary) !important;
  background-color: transparent;
}

/* Hover effect: change border color to black */
 .ant-radio-wrapper .ant-radio-inner:hover {
  border-color: var(--secondary) !important;
}

/* Style for the checked radio button */
 .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: var(--secondary);
  border: 2px solid;
  background-color: transparent;
}

/* Set the dot in the center to black (both for checked and unchecked) */
 .ant-radio-wrapper .ant-radio-inner::after {
  background-color: var(--secondary);
 
}

