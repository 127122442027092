.select-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.styled-select {
  width: 100%;
  padding: 20px 16px 7px 16px;
  border: 1px solid #ccc;
  border-radius: 50px;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  color: #333;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  height: 48px;
  cursor: pointer;
}

.styled-select.filled {
  color: #000;
}

.select-label-1 {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  color: var(--textGray10);
  transition: all 0.2s ease;
  pointer-events: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.select-label-1.active {
  top: 16px;
  padding: 0 5px;
  font-size: 9px;
  color: var(--textGraySand);
}

.ant-select-focused + .select-label-1 {
  top: 16px;
  padding: 0 5px;
  font-size: 9px;
  color: #0a53e4;
}
.styled-select option {
  background-color: #f5f5f5;
  color: #333;
  font-size: 14px;
  padding: 10px;
}

.required {
  color: red;
}

.month_select .styled-select {
  font-size: 12px !important;
}

.styled-select .ant-select-selector {
  border: none !important;
  padding: 0px !important;
  box-shadow: none !important;
}

.styled-select:focus .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}

.disabled-select .styled-select {
  background: rgba(235, 235, 235, 1);
  cursor: not-allowed;
}

.disabled-select .styled-select .ant-select-selector {
  background-color: transparent !important;
}

.common-dropdown {
  padding: 0px;
  border-radius: 4px;
  box-shadow: 0px 0px 16px 3px #000e3333;
}

.common-dropdown .ant-select-item {
  padding: 12px 24px !important;
  border-radius: 0px !important;
}

.common-dropdown .ant-select-item-option-selected {
  background-color: transparent !important;
  color: var(--textGray100) !important;
  font-weight: 400 !important;
}

.common-dropdown .ant-select-item .ant-select-item-option-content {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: var(--textGray100);
}

.common-dropdown .ant-select-item-option-active {
  background-color: var(--primaryLightBlue5) !important;
}

.common-dropdown
  .ant-select-item-option-active
  .ant-select-item-option-content {
  color: var(--primaryBlue70) !important;
}
