.textWithDivider {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;

  .line {
    flex: 1;
    height: 1px;
    background-color: var(--border);
  }

  .text {
    font-size: 14px;
    color: var(--textGray101);
    font-family: "roboto";
    font-weight: 400;
    line-height: 20.22px;
    /* opacity: 0.6; */
  }
}
