.header-main {
  background-color: var(--bgWhite);
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  padding: 20px 3%;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15); */
  border-bottom: 1px solid var(--border);
  gap: 35px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.header-height-spacing {
  height: 180px;
}

.header-main .headr-row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headr-row1 .logo-img {
  width: 135px;
  cursor: pointer;
}

.headr-row1 .search-input-parent .search-dropdown {
  border-right: 2px solid var(--border) !important;
}

.headr-row1 .search-input-parent .search-dropdown .ant-select-selector {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border: none;
  width: 60px;
  color: var(--placeholder);
  margin-right: 2px;
}

.headr-row1 .search-input-parent .ant-select-focused .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}

.header-main .headr-row1 .search-input-parent {
  height: 50px;
  width: 45%;
  border: 2px solid var(--border);
  border-radius: 25px;
  padding: 3px 3px 3px 10px;
  overflow: hidden;
}

.header-main .headr-row1 .search-input-parent .ant-select-single {
  height: auto;
}

.header-main .headr-row1 .search-input-parent .search-butn {
  height: auto;
  border-radius: 25px;
  background-color: var(--secondary);
  font-size: 15px;
  margin-left: 3px;
}

.header-main .headr-row1 .search-input-parent .search-field {
  padding: 0px 20px !important;
}

.header-main
  .headr-row1
  .search-input-parent
  .search-field
  .ant-select-selector {
  border: none;
  padding: 0px;
}

.header-main .headr-row1 .search-input-parent .search-field:focus {
  box-shadow: none;
  outline: none;
}

.header-main .headr-row1 .search-input-parent .search-field::placeholder {
  color: var(--placeholder);
  font-size: 15px;
}

.header-product-search-dropdown {
  padding: 0px;
  border-radius: 4px;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
}

.header-product-search-dropdown
  .ant-select-item-option-content
  .search-dropdown-item {
  display: flex;
  align-items: center;
  gap: 12.17px;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
}

.header-product-search-dropdown .ant-select-item-option:hover,
.header-product-search-dropdown .ant-select-item-option:hover * {
  background-color: var(--primaryLightBlue5);
  color: var(--primary);
}

.header-product-search-dropdown .ant-select-item {
  padding: 9px 18px;
}

.header-main .headr-row1 .btns-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.header-main .headr-row1 .btns-parent .btn {
  border: none;
  box-shadow: none;
  display: flex;
  gap: 0px;
  padding: 0px;
  height: fit-content;
  color: var(--primaryText);
}

.header-main .headr-row1 .btns-parent .btn svg {
  font-size: 33px;
}

.headr-row1 .btns-parent .btn > span {
  display: flex;
  text-align: start;
  flex-direction: column;
  line-height: normal;
  font-size: 10px;
}

.headr-row1 .btns-parent .btn > span > p {
  font-size: 12px;
}

.headr-row1 .btns-parent .btn > span .cart-itemsCount-badge .ant-scroll-number {
  background-color: var(--primaryText);
  font-size: 10px;
  height: 15px;
  line-height: 15px;
}

.header-main .headr-row2 {
  display: flex;
}

.headr-row2 .category-dropdown-trigger {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--textGray70);
  padding: 07px 13px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  border: none;
}

.headr-row2 .category-dropdown-trigger:hover {
  color: var(--primary);
  text-decoration: underline;
}

.headr-row2 .category-dropdown-trigger span {
  display: inline-block;
  font-family: "Inter" !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
}

.headr-row2 .category-dropdown .ant-select-arrow {
  margin-right: 6px;
}

.headr-row2 .category-list {
  list-style: none;
  display: flex;
  align-items: center;
  overflow-x: auto;
  gap: 20px;
  margin: 0px 0px 0px 20px;
}

.headr-row2 .category-list .category-list-item {
  color: var(--textGray70);
  padding: 07px 13px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
}

.headr-row2 .category-list .category-list-item:hover {
  color: var(--primary);
  text-decoration: underline;
}

.headr-row2 .ant-select-focused .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}

.headr-row2 .category-list::-webkit-scrollbar {
  display: none;
}

.home-dropdown-overlay {
  min-width: 200px !important;
  max-width: 200px !important;
  height: fit-content;
  .ant-dropdown-menu {
    min-height: 100%;
    max-height: 100%;
    padding: 12px 0px 0px 0px;
    .ant-dropdown-menu-item-active {
      background-color: transparent !important;
    }
    .ant-dropdown-menu-item {
      padding: 0px;
    }
    .dropdown-content-parent {
      .user-name-label-parent {
        padding: 4px 18px;

        h2 {
          font-family: "Inter";
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          color: var(--textGray100);
        }
        .userStatus {
          font-size: 10px;
          font-weight: 400;
          line-height: 16px;
        }
        .user-verified-status {
          color: #08a164;
        }
        .user-notverified-status {
          color: #ff0f0f;
        }
      }
      .nav-items {
        padding: 0px 18px;
        border-top: 2px solid var(--textGray10);
        border-bottom: 2px solid var(--textGray10);
        div {
          height: 36px;
          display: flex;
          align-items: center;
          font-family: "Inter";
          font-size: 12px;
          font-weight: 400;
          line-height: 18.249px;
          color: var(--textGray100);
          letter-spacing: -0.24px;
        }
      }
      .nav-items:nth-of-type(3) {
        border-top: none;
        div {
          color: var(--textGraySand);
        }
      }
      .btn-parent {
        padding: 22px 18px;
        button {
          width: 100%;
          font-family: "Inter";
          font-size: 12px;
          font-weight: 400;
          line-height: 14.4px;
          text-align: center;
          padding: 0px 8px;
          height: 38px;
        }
      }
      .unauthorized-dropdown-btns {
        padding: 22px 18px;
        button {
          width: 100%;
          font-family: "Inter";
          font-size: 12px;
          font-weight: 400;
          line-height: 14.4px;
          text-align: center;
          padding: 0px 8px;
          height: 38px;
        }
        button:nth-of-type(1) {
          background-color: var(--primary);
          color: var(--bgWhite);
          margin-bottom: 10px;
        }
      }
    }
  }
}

/* shopping card */
.cart-parent {
  position: relative;
}

.shopping-card {
  width: 354px;
  height: 510px;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 20px 25px;
  position: absolute;
  right: 1px;
  top: 50px;
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.3s ease-in-out;
  z-index: 1000;
  visibility: hidden; /* Ensure it is hidden when not shown */

  h4 {
    /* font-family: "Poppins"; */
    font-size: 18.53px;
    font-weight: 500;
    line-height: 27.8px;
    padding-bottom: 30px;
  }
  .divider {
    margin: 20px 0px;
  }
  .shopping-card-body {
    max-height: 300px;
    overflow: auto;
    /* padding-bottom: 100px; */

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }

  .cart-parent {
    display: flex;
    gap: 20px;

    .cart-image {
      /* flex: 1; */
      height: 123px;
      width: 123px;
      border: 2px solid var(--bgSecondary);
      border-radius: 5px;
      padding: 20px;
      background-color: var(--bgPrimary);
      /* object-fit: contain; */

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .cart-details {
      flex: 3;
      display: flex;
      flex-direction: column;
      gap: 9px;

      p {
        font-size: 16px;
        font-weight: 300;
      }
      .cart-list {
        padding: 6px 5px;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        background-color: var(--bgSecondary);

        img {
          background-color: var(--bgPrimary);
          border: 1px solid var(--border);
          border-radius: 5px;
          padding: 3px;
          background-size: contain;
          width: 30px;
          height: 30px;
        }

        .cart-list-details p {
          font-size: 9px;
          font-weight: 500;

          span {
            font-weight: 600;
            padding-right: 3px;
          }
        }
      }
    }
  }

  .cart-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;
    padding-bottom: 45px;
    border-top: 1px solid var(--border);
    position: absolute;
    bottom: 0;
    left: -1px;
    width: 100%;
    z-index: 1000;
    background-color: white;

    .cart-btn {
      background-color: var(--primary);
      color: white;
      width: 100%;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15); */
    border-bottom: 10px solid rgb(236, 235, 235);
    z-index: 1001;
  }
}

.show-card {
  opacity: 1;
  transform: translateY(0);
  visibility: visible; /* Make it visible when active */
}

.hide-card {
  opacity: 0;
  transform: translateY(-20px);
  visibility: hidden;
}
.category-menu-popup
  .ant-dropdown-menu-submenu-active
  .ant-dropdown-menu-submenu-title {
  background-color: transparent !important;
}
.category-menu-popup .ant-dropdown-menu-submenu-open,
.category-menu-popup
  .ant-dropdown-menu-submenu-open
  .ant-dropdown-menu-submenu-title {
  background-color: var(--primaryLightBlue5) !important;
  color: var(--primaryBlue70) !important;
}

@media only screen and (max-width: 1070px) {
  .header-main {
    .headr-row1 {
      .search-input-parent {
        flex: 1;
        margin: 0px 20px;
      }
      .btns-parent {
        .btn {
          .text {
            display: none;
          }
          .cart-itemsCount-badge {
            position: absolute;
            top: 2px;
            right: -4px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .header-main {
    .headr-row1 {
      .search-input-parent {
        .search-dropdown {
          display: none;
        }
        .search-butn {
          padding: 7px 15px;
          span {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .header-height-spacing {
    height: 140px;
  }
  .header-main {
    height: 140px;
    padding: 10px;
    gap: 16px;
    .headr-row1 {
      .logo-img {
        width: 85px;
      }
      .search-input-parent {
        margin: 0px 10px;
        .search-field {
          padding: 0px 7px !important;
        }
      }
      .btns-parent {
        gap: 12px;
        .btn {
          svg {
            font-size: 28px;
          }
          .cart-itemsCount-badge {
            top: 0px;
            right: -7px;
          }
        }
      }
    }
    .headr-row2 {
      .category-list {
        margin-left: 20px;
        gap: 14px;
        .category-list-item {
          font-size: 14px;
        }
      }
      .category-dropdown-trigger {
        span {
          font-size: 13px !important;
          line-height: 16.2px !important;
        }
      }
    }
  }
}
