.filter-and-products-main-row {
  padding: 80px 0px 0px 5%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 30px;
  background: var(--bgSecondary);
}

.filter-and-products-main-row .filterBy-main {
  min-width: 260px;
  max-width: 260px;
  height: fit-content;
  border: 2px solid var(--border);
  border-radius: 10px;
  padding: 20px;
  margin: 0 auto;
  background: var(--bgWhite);
}

.filterBy-main > h5 {
  font-size: 25px;
  margin: 0px 0px 40px 0px;
  font-weight: 700;
  line-height: 24px;
}

.filterBy-main > div:first-child {
  display: flex;
  flex-direction: column;
}

.filters-parent {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.filters-parent .filter-title {
  font-size: 18px;
  font-weight: 500;
}

.filters-parent .price-filter-main .price-inputs-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.filters-parent .price-filter-main .price-inputs-parent > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filters-parent .price-filter-main .price-inputs-parent > div > label {
  color: var(--placeholder);
  font-size: 15px;
}

.filters-parent .price-filter-main .price-input {
  padding: 6px 0px;
  font-size: 16px;
}

.filterBy-main .filters-parent .single-filter-parent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filterBy-main .filters-parent .single-filter-parent .ant-checkbox + span {
  font-weight: 400;
}

.load-more-btn {
  margin: 50px auto 30px auto !important;
}

.filters-tag-parent-div {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 50px 5% 0px 5%;
  background-color: var(--bgSecondary);
}

.filters-tag-parent-div .filter-tag {
  background-color: var(--shadow);
  color: var(--primaryText);
  font-family: "Inter";
  border-radius: 30px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  padding: 14px 30px;
  cursor: pointer;
  min-width: 150px;
  max-width: fit-content;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filters-tag-parent-div .reset-button {
  border-color: var(--textGray53);
  color: var(--textGray53);
}

.filter-and-products-main-row .products-parent {
  display: flex;
  flex-wrap: wrap;
  row-gap: 44px;
  column-gap: 30px;
  flex: 1;
}

.categories-tabs-main-container {
  padding: 50px 5% 0px 5%;
  background-color: var(--bgSecondary);
}

.categories-tabs-main-container .categories-tabs-list {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  overflow-x: auto;
}

.categories-tabs-main-container .categories-tabs-list::-webkit-scrollbar {
  display: none;
}

.categories-tabs-main-container .categories-tabs-list .category-tab-item {
  border: 1px solid var(--primaryText);
  color: var(--primaryText);
  padding: 16px 15px;
  border-radius: 100px;
  min-width: 89px;
  display: inline-table;
  max-width: fit-content;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  white-space: nowrap;
}

.categories-tabs-main-container
  .categories-tabs-list
  .active-category-tab-item {
  background-color: var(--primaryText);
  color: var(--bgWhite);
}
