.ant-card .ant-card-body {
  padding: 0px;
  /* border: 1px solid; */
  /* border-radius: 8px; */
}
.ant-image {
  width: 100%;
  position: relative;
  display: inline-block;
  border: 1px solid var(--border);
  border-radius: 8px;
  background-color: var(--shadow);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-image .ant-image-img {
  width: 100%;
  height: auto;
  vertical-align: middle;
  border-radius: 8px;
  margin: 0px auto;
  display: flex;
}
.ant-image .main_product_img {
  padding: 97px 0px;
  width: 60% !important;
  position: relative;
}

.heart_icon {
  position: absolute !important;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

