.addCardModal {
  min-width: 620px;
  max-width: fit-content;
  .ant-modal-content {
    padding: 30px 35px;
  }
  .addCardForm {
    .common-input {
      height: 48px;
      border-radius: 100px;
      border: none;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.28px;
      outline: none;
      box-shadow: none;
      border: 1px solid var(--textGraySand);
      color: var(--textGray100);
      padding: 12px 15px;
    }
    .StripeElement {
      padding: 14px 15px 12px 15px;
    }
  }
  .addCardForm > h2 {
    color: var(--textGray100);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin: 40px 0px 20px 0px;
  }
}
