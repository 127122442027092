.product_discription {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: flex-start;
  gap: 15px;
  padding-left: 50px;
  padding-bottom: 50px;
  height: auto;
}
.product_title {
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 20px;
}

.product_discription .product-title-parent {
  display: flex;
  align-items: center;
  gap: 20px;
}

.product_discription .product-title-parent .favorite_icon {
  /* position: absolute !important; */
  /* top: 10px; */
  /* right: 10px; */
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  /* padding: 8px; */
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product_limitedAddition {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
}
.product_limitedAddition .product_sold {
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.stockTag {
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 20px;
  border: 1px solid;
}

.limited_stock {
  color: #ff5200;
  border-color: #ff5200;
  background-color: #ffd3be;
}

.inStock {
  background-color: #e8fcf2;
  color: #0ada72;
  border-color: #0ada72;
}

.outOfStock {
  background-color: #ffe1cc;
  color: red;
  border-color: red;
}

.product_price {
  padding: 15px 0px;
}
.categories {
  display: flex;
  align-items: center;
  gap: 7px;
}
.product_limitedAddition .categories strong {
  font-size: 16px;
  font-weight: 400;
}

.categories .categories_hash {
  background-color: #1e3442;
  color: white;
  padding: 5px;
}
.weight_varient {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
}

.prefered-variant {
  margin-top: 40px;
}

.prefered-variant h3 {
  margin-bottom: 20px;
}

/* .weight_varient .ant-select-selector{
width: 290px !important;
height: 48px !important;
border-radius: 100px;
/* margin-bottom: 40px; */
/* }  */

.weight_varient .styled-select {
  padding: 7px 16px 7px 16px;
}
.weight_varient h3 {
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
}

.weight_pkt_button {
  position: relative;
  border-radius: 30px;
  padding: 0px 20px;
  font-weight: 300;
  font-size: 14px;
  background-color: transparent;
  color: var(--textGray100);
  border: 1px solid var(--textGray100);
  height: 38px;
}
.active_weight_button {
  background-color: black;
  color: white;
}
.weight_pkt_button_total {
  font-size: 9px;
  color: white;
  padding: 5px;
  border: 2px solid white;
  background-color: black;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  padding: 8px;
  margin: 0px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: -3px;
}
.quantity_select_section {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.quantity_select_section h5 {
  font-size: 20px;
  font-weight: 400;
}
.quantity_select_section .quantity_select {
  display: flex;
  align-items: "center";
  gap: 10px;
  border: 1px solid var(--borderD8);
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;
  color: #333333;
}
.quantity_select_section .quantity_select .quentity_btn {
  border-radius: 50%;
  padding: 5px 10px;
  border: 1px solid var(--borderD8);
  font-size: 22px;
  width: 40px;
  height: 40px;
  background: transparent;
  cursor: pointer;
  color: #60666c;
}
.login_btn {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  border: none;
  background: transparent;
  font-size: 16px;
  font-weight: 300;
  height: 40px;
  border: 1px solid var(--primary);
  color: var(--primary);
  border-radius: 20px;
}
.login_btn:hover,
.login_btn:active {
  /* color: var(--bgWhite) !important; */
  /* background: var(--primary) !important; */
}
.group_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;

  @media (max-width: 402px) {
    flex-direction: column;
    gap: 5px;
  }
}
@media (max-width: 768px) {
  .product_discription {
    padding-left: 0px;
    padding-bottom: 10px;
  }
}
