.hero-section {
  height: 600px;
  width: 100%;
  background-color: var(--shadow);
}

.hero-section .carousel-main {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-section .ant-carousel {
  height: 90%;
}

.hero-section .carousel-main .carousel-item {
  height: 100%;
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.hero-section .carousel-main .carousel-item > div {
  width: 60% !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.hero-section .carousel-main .carousel-item .hdng {
  font-size: 55px;
  line-height: 62px;
  font-weight: 500;
  text-align: center;
  font-family: 'Inter';
}

.hero-section .carousel-main .carousel-item .sub-hdng {
  font-size: 23px;
  line-height: 30px;
  font-weight: 300;
  text-align: center;
}

.hero-section .carousel-main .carousel-item .carousel-btn {
  border-radius: 30px;
  padding: 15px;
  width: 200px;
  margin-top: 30px;
  font-weight: 300;
}

.hero-section .carousel-main .slick-dots li button {
  background-color: var(--placeholder);
  opacity: unset !important;
  width: 17px;
  height: 17px;
  border-radius: 50%;
}

.hero-section .carousel-main .slick-dots .slick-active button {
  background-color: var(--secondary) !important;
}

.hero-section .ant-carousel .slick-list,
.hero-section .ant-carousel .slick-slider .slick-track,
.hero-section .ant-carousel .slick-list .slick-slide.slick-active {
  width: 100% !important;
}

@media only screen and (max-width: 1000px) {
  .hero-section .carousel-main .carousel-item .hdng {
    font-size: 40px;
    line-height: 47px;
  }

  .hero-section .carousel-main .carousel-item .sub-hdng {
    font-size: 18px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 720px) {
  .hero-section .carousel-main .carousel-item > div {
    width: 95% !important;
  }
}