.product-detail-main {
  padding: 14px 80px;
}

.product-detail-main .anticon svg {
  display: inline-block;
  font-size: 18px;
}

.back-breadcrumb .breadcrumb-item {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  img{
    width: 10px;
  }
  .text{
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0%;
  }
}

/* FOR PREVIEWiMAGE */

/* product detail right side */
/* KEY ATTRIUTES */

.key_attributes {
  margin-top: 60px;
  padding-left: 30px;

  @media (max-width: 420px) {
    padding-left: 0px;
  }
}
.key_attributes_border {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: 420px) {
    align-items: center;
  }
}

.delivery_method {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 50px;
  margin-top: 20px;
}
.delivery_method .delivery_method_main h2 {
  font-weight: 700;
}
.delivery_method .delivery_method_main p {
  padding-top: 10px;
  font-weight: 400;
  font-size: 18px;
  color: #1f1f10;
}

/* PRODUCT RATTING */
.product_ratting {
  padding: 53px 100px 88px 120px;
  margin-top: 70px;
  /* padding-left: 30px; */
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  /* width: 50%; */
  gap: 10px;
  background: #f1f2f3;
}

.product_ratting .tabs {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 54px;
}

.product_ratting .tabs button {
  /* width: 137px; */
  /* height: 54px; */
  padding: 16px 15px;
  border-radius: 100px;
  border: 1px solid #101112;
  font-size: 16px;
  font-weight: 400;
  color: #101112;
  background: transparent;
  text-transform: capitalize;
}

.product_ratting .tabs .active-btn {
  /* width: 137px; */
  /* height: 54px; */
  padding: 16px 15px;
  border-radius: 100px;
  border: 1px solid #101112;
  font-size: 16px;
  font-weight: 400;
  color: #e5f3ff;
  background: #101112;
  text-transform: capitalize;
}

.product_ratting h3 {
  padding-bottom: 30px;
}
.product_ratting .rating_section {
  display: flex;
  align-items: center;
  gap: 10px;
}
.rating_section h1 {
  font-size: 52px;
}
.rating_section .rating_reviews {
  display: flex;
  flex-direction: column;
}
.rating_reviews div {
  display: flex;
}
.review_filter_btn_sec {
  padding-top: 20px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}
.review_filter_btn_group {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.review_filter_button {
  background: transparent;
  border: 1px solid rgb(216, 216, 216);
  color: rgb(59, 59, 59);
  border-radius: 20px;
  padding: 5px 12px;
  margin-right: 5px;
  min-width: 55px;

  &:hover {
    color: var(--textGray100);
    border: 1px solid var(--textGray100);
    background-color: transparent;
  }
}

.review_filter_button.active{
  border-color: var(--textGray100);
}

.rating_sorting {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: rgb(49, 49, 49);
}
.rating_sorting .rotate {
  transform: rotate(90deg);
  font-size: 24px;
}
.rating_sorting span {
  font-weight: 600;
}
.rating_sorting .ant-select .ant-select-selector {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.rating_sorting .ant-select .ant-select-arrow {
  color: black;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  margin-top: 2px;
  font-size: 16px;
  font-weight: 500;
}

/* ************************ */
.relate_product {
  padding: 80px 100px;
  .row {
    display: flex;
    gap: 27px;
    flex-wrap: wrap;
  }
  @media (max-width: 786px) {
    padding: 40px 10px;
  }
}

.relate_product h3,
.relate_search h3 {
  padding-bottom: 20px;
}
.relate_search {
  padding: 40px 100px;
  margin-bottom: 30px;
  @media (max-width: 786px) {
    padding: 40px 10px;
  }
}

@media (max-width: 786px) {
  .product_ratting {
    margin-top: 70px;
    padding-left: 0px;
    width: 100%;
  }
  .product-detail-main {
    padding: 10px 40px;
  }
}
@media (max-width: 622px) {
  .product-detail-main {
    padding: 10px 40px;
  }
}
@media (max-width: 420px) {
  .product-detail-main {
    padding: 10px 20px;
  }
}
