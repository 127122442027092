.verifyCodeModal {
  width: 656px !important;
  box-shadow: 0px 0px 25px 0px #ccc;
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 53px 0px;
    border-radius: 1px;
  }
}

.verifyCodeBody {
  /* width: 300px; */
  display: flex;
  flex-direction: column;
  align-items: center;

  .heading {
    color: var(--textGray100);
    margin-bottom: 9px;

    font-size: 29px;
    font-weight: 700;
    line-height: 34.8px;

    @media (max-width: 440px) {
      font-size: 22px;
    }
  }
  .description {
    color: var(--textGray70);
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 20.22px;

    @media (max-width: 440px) {
      font-size: 10px;
    }
  }
  .codeText {
    color: var(--textGray70);
    font-family: "Inter";
    font-size: 14px;
    font-weight: 700;
    line-height: 20.22px;
  }

  .otpInput {
    padding-top: 50px;
    margin-bottom: 37px;
    width: 343px;

    p {
      color: var(--textGray70);
      margin-bottom: 13px;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 20.22px;
    }

    .ant-otp {
      column-gap: 11px;
      input {
        padding: 6px 11px;
        border-radius: 4px;
        background-color: transparent;
        width: 48px;
        height: 48px;
        border: 1px solid var(--borderGray);
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: var(--borderGray);
        letter-spacing: -0.32px;
        &:focus {
          box-shadow: none;
        }
        &:not(:placeholder-shown) {
          background-color: var(--bgPrimary);
          /* width: 48px;
          height: 48px; */
        }
        &:placeholder-shown {
          background-color: transparent;
          /* width: 48px;
          height: 48px; */
        }
        &[value=""] {
          background-color: transparent;
          /* width: 48px;
          height: 48px; */
        }

        @media (max-width: 440px) {
          width: 28px;
          height: 28px;
        }
      }
    }

    @media (max-width: 440px) {
      text-align: center;
    }
  }

  .links {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    p {
      cursor: pointer;
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      color: var(--textGray70);
      font-weight: 400;
      line-height: 20.22px;
    }

    p:not(:first-child) {
      color: var(--primaryBlue90);
    }
    p:hover {
      color: var(--secondary);
    }
  }

  .pi55 {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    counter-reset: var(--btnPrimary);
  }
}
