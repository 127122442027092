.banner-section {
    height: 424px;
    width: 100%;
    background-color: var(--shadow);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .banner-section > .text-section {
    width: 40% !important;
    height: 90%;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
  }
  
  .banner-section > .text-section .hdng {
    text-align: center;
    font-size: 41px;
    font-weight: 700;
    line-height: 49.2px;
  }
  
  .banner-section > .text-section .sub-hdng {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: center;
  }
  
  .banner-section > .greeting-text {
    width: 100%;
    padding: 19px 80px;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    color: var(--btnPrimary);
    background-color: var(--bgBlue);
  }

  @media only screen and (max-width: 1190px) {
    .banner-section .text-section {
      width: 70% !important;
    }
    .banner-section .text-section .hdng {
      font-size: 40px;
      line-height: 47px;
    }
  
    .banner-section .text-section .sub-hdng {
      font-size: 18px;
      line-height: 25px;
    }
  }
  
  @media only screen and (max-width: 650px) {
    .banner-section .text-section {
      width: 95% !important;
    }
    .banner-section .text-section .hdng {
      font-size: 30px;
      line-height: 37px;
    }
  
    .banner-section .text-section .sub-hdng {
      font-size: 15px;
      line-height: 22px;
    }
  
    .banner-section > .greeting-text {
      padding: 19px 40px;
    }
  }
  