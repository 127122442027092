.footer-main {
  width: 100%;
  background-color: var(--bgSecondary);
  height: auto;
  padding: 0px 7%;
  position: relative;
}

.footer-main .row1 {
  padding: 50px 0px 16px 0px;
  border-top: 1px solid var(--border);
  margin-bottom: 92px;
}

.footer-main .row1 .col {
  display: flex;
  flex-direction: column;
}

.footer-main .row1 .col > div .hdng {
  font-size: 24px;
  color: var(--primaryText);
  width: fit-content;
}

.footer-main .row1 .col > div .nav-list {
  list-style: none;
  margin: 25px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 20px;
}

.footer-main .row1 .col:nth-of-type(2) {
  align-items: center;
}

.footer-main .row1 .col:last-child {
  align-items: end;
}

.footer-main .row1 .nav-list .nav-item {
  color: var(--primaryText);
  font-weight: 400;
  font-size: 17px;
  cursor: pointer;
}

.footer-main .row2 {
  display: flex;
  width: calc(100% + 5px);
  height: auto;
  background-color: var(--bgSecondary);
  /* position: fixed; */
  bottom: 0;
  left: 0;
  z-index: 1000;
  /* padding: 0px 7% 42px 7%; */
  padding-bottom: 42px;
  border-top: 1px solid var(--border);
}

.footer-main .row2 > div {
  padding-top: 24px;
}

.footer-main .row2 .text {
  font-size: 15px;
  color: var(--textGray70);
}

.footer-main .row2 .social-icons-parent .social-icon {
  color: var(--textGray70);
  font-size: 25px;
  cursor: pointer;
}

.footer-main .row2 .logo-text-parent {
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
}

.footer-main .row2 .social-icons-parent {
  display: flex;
  width: 100%;
  justify-content: end;
  gap: 30px;
}

.footer-main .row2 .logo-text-parent img {
  width: 150px;
}

@media only screen and (max-width: 400px) {
  .footer-main .row1 .col .hdng {
    font-size: 20px;
  }
  .footer-main .row1 .nav-list .nav-item {
    font-size: 13px;
  }

  .footer-main .row1 .col .nav-list {
    margin: 20px 0px 0px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-main {
    padding: 0px 4%;
  }
  .footer-main .row1 .col {
    align-items: start !important;
  }

  .footer-main .row2 .social-icons-parent {
    gap: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .footer-main {
    padding: 0px 2%;
  }
  .footer-main .row1 .col {
    align-items: start !important;
  }
  .footer-main .row2 .logo-text-parent img {
    width: 100px !important;
  }
  .footer-main .row2 .logo-text-parent .text {
    font-size: 12px;
  }

  .footer-main .row2 .social-icons-parent .social-icon {
    font-size: 20px;
  }
}

@media only screen and (max-width: 490px) {
  .footer-main .row1{
    margin-bottom: 135px;
  }
  .footer-main .row2 {
    flex-direction: column;
  }
  .footer-main .row2 .social-icons-parent {
    align-items: center;
    justify-content: start;
  }
}
