/* modal */
.drawer_main {
  height: calc(100vh - 380px);
  overflow: auto;
  /* border: 1px solid red; */
}
.drawer_main::-webkit-scrollbar {
  width: 5px;
}
.drawer_main .drawer_pricing {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.drawer_pricing p {
  font-size: 19px;
  font-weight: 300;
}
.drawer_main .drawer_weight_varient {
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.drawer_main .drawer_weight_varient h2 {
  font-weight: 500;
}
.drawer_main .drawer_weight_varient .varient_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.drawer_weight_varient .varient_list p {
  font-size: 16px;
}

.drawer_weight_varient .varient_list .quantity_select_section {
  margin-top: 0px;
}
.drawer_schedule {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.drawer_schedule .schedule_switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.drawer_schedule .schedule_switch p {
  font-size: 22px;
  font-weight: 500;
}
.schedule_btn_group {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 10px;
}
.schedule_btn_group Button,
.delivery_type_btn Button {
  width: 100%;
  &:hover {
    background-color: black !important;
    color: white;
  }
}
.schedule_btn {
  background-color: black;
  color: white;
  &:hover {
    background-color: black !important;
    color: white;
  }
}

.delivery_type_modal {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h3 {
    font-size: 22px;
  }

  .delivery_type_btn {
    display: flex;
    gap: 10px;
  }
}

.drawer_shipping {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.drawer_shipping h3 {
  font-weight: 500;
  font-size: 20px;
}
.drawer_shipping p {
  font-weight: 300;
  font-size: 18px;
}
.drawer_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  /* border: 1px solid red; */
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  padding: 40px 20px 20px 20px;
}
.drawer_footer .shipping_fee {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shipping_fee.p_b {
  padding-bottom: 20px;
}
.shipping_fee .item {
  font-size: 20px;
  font-weight: 400;
}
.shipping_fee .price {
  font-size: 16px;
  font-weight: 600;
}

