
.left-side-container {
  padding: 20px 30px;


  @media (max-width:420px){
    padding: 20px 10px;
  }
}
.left-side-container .personal-info {
  font-size: 14px;
  font-weight: 300;
  display: flex;
  gap: 7px;
  align-items: center;
  padding-bottom: 20px;
}

.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media (max-width:870px){
    flex-direction: column;
  }
}
.d-flex .ant-form-item {
  width: 100%;
}
.remember_title {
  font-size: 14px;
  font-weight: 300;
}
.payment_btn {
  border-radius: 20px;
  padding: 12px 40px;
  font-weight: 300;
}
.payment_method {
  display: flex;
  align-items: center;
  gap: 20px;
}
.payment_method .icons{
   color: black;
}

.right-side {
  flex: 1;
}
.right-side .right-side-container{
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding:25px 20px;
}
.summary{
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 30px;
}
.total-pay{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.total-pay p{
    color:var(--textGray100);
    font-weight: 300;
    font-size: 14px;
}
.total-pay .discount{
  color: var(--secondary);
}
.total-pay.pay-select{
  padding-top: 25px;
}
.protection-order{
padding-top: 20px;
}
.protection-order > h4{
    padding-bottom: 10px;
}
.order-cont{
display: flex;
flex-direction: column;
}
.order-cont-heading{
    padding-top: 5px;
    display: flex;
    gap: 5px;
    align-items: center;
}
.order-cont-heading p{
    font-size: 14px;
    font-weight: 400;
}
.order-cont > p{
    color: var(--textGray70);
    padding-left: 21px;
    font-size: 10px;
    font-weight: 300;
    line-height: 1.5;
}
.order-cont > p span{
  text-decoration: underline;
  cursor: pointer;
}
.order-trade{
    padding-top: 20px;
}
.order-trade p{
    font-size: 10px;
    font-weight: 300;
    color: var(--textGray70);
}
.order-trade p>span{
    color: black;
    font-weight: 500;
}

.btn_desabled{
  background-color: var(--textGray70);
  color: white;
  cursor: not-allowed;
  
}