.authLayout {
  width: 100%;
  min-height: 100dvh;

  display: flex;
  flex-direction: column;

  /* .authHeader {
    width: 100%;
    height: 100px;
    border-bottom: 1px solid var(--border);
    padding-inline: 100px;

    display: flex;
    align-items: center;
  } */

  .authContent {
    width: 100%;

    flex: 1;

    display: flex;
    justify-content: center;
  }
}
